import { memo } from "react";
import ReactECharts from "echarts-for-react";

const RadarChart = ({ data, onIndicatorClick }) => {
  const option = {
    radar: {
      indicator: [
        { name: "최신화율", max: 5 },
        { name: "신뢰성", max: 5 },
        { name: "안전성", max: 5 },
        { name: "양품률", max: 5 },
        { name: "생산성", max: 5 },
        { name: "가동률", max: 5 },
      ],
      triggerEvent: true,
      axisName: {
        color: "#fff",
        backgroundColor: "#666",
        borderRadius: 3,
        padding: 4,
        fontStyle: "Noto Sans Korean",
      },
    },
    series: [
      {
        name: "설비 평가표",
        type: "radar",
        data: [
          {
            value: data,
            name: "설비 평가표",
            label: {
              show: true,
              formatter: function (params) {
                return params.value;
              },
            },
          },
        ],
        areaStyle: {
          color: {
            type: "radial",
            x: 0.5,
            y: 0.5,
            r: 0.5,
            colorStops: [
              {
                offset: 0,
                color: "rgba(255, 145, 124, 0.1)",
              },
              {
                offset: 1,
                color: "rgba(255, 145, 124, 0.9)",
              },
            ],
            global: false,
          },
        },
      },
    ],
  };

  const onEvents = {
    click: (params) => {
      onIndicatorClick(params.name);
    },
  };

  return (
    <ReactECharts
      option={option}
      onEvents={onEvents}
      style={{ width: "100%" }}
    />
  );
};

export default memo(RadarChart);

import React, { useEffect, useState, useRef } from "react";
import ECharts from "echarts-for-react";
import { useMasterAssetDataForGraph } from "../../hooks/useMasterAssetDataForGraph";
import { useMaintenanceResultExpenseData } from "../../hooks/useMaintenanceResultExpenseData";
import { useRiskMaintenance } from "../../hooks/useRiskMaintenance";
import { useDataInspection } from "../../hooks/useDataInspection";
import { useBrokenData } from "../../hooks/useBrokenData";
import { useProductivityData } from "../../hooks/useProductivityData";
import { useDispatch } from "react-redux";
import { setPopShowing, setPopStatus, setMonth } from "../../slice/authSlice";
import * as s from "./styles";
import { useFusionMatrix } from "../../hooks/useFusionMatrix";
import { useFailureData } from "../../hooks/useFailureData";
import { useWorkerData } from "../../hooks/useWorkerData";
import { useDataLineWorkerData } from "../../hooks/useDataLineWorkerData";
import { useCapability } from "../../hooks/useCapability";
import { useProductionPlayMonthly } from "../../hooks/useProductionPlanMonthly";

function Chart({ index, sendMessage, isUnityChattingOpend }) {
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [is4k, setIs4k] = useState(false);
  const chartsRef = useRef(null);
  let chartInstance = null;
  const handleChartClick = (zrParams, option) => {
    setPopStatus(false);
    setPopShowing("");
    const chart = chartInstance;
    const isSeriesTypeScatter = zrParams.seriesType === "scatter";
    const yAxisName = isSeriesTypeScatter ? zrParams.seriesName : zrParams.name;
    const clickedItem = assetData.find((item) => item.asset_name === yAxisName);
    if (clickedItem) {
      const { mchId, asset_floor } = clickedItem;
      console.log(mchId, asset_floor);
      sendMessage("Canvas", "SetFloorVisible", asset_floor);
      sendMessage("Canvas", "SetCameraMachineView", mchId);
    }
  };
  // const handleChartClick = (zrParams, option) => {
  //   setPopStatus(false);
  //   setPopShowing("");
  //   const chart = chartInstance;
  //   console.log("zrParams : ", zrParams);
  //   const pointInPixel = [zrParams.offsetX, zrParams.offsetY];
  //   if (chart.containPixel("grid", pointInPixel)) {
  //     const pointInGrid = chart.convertFromPixel(
  //       { seriesIndex: 0 },
  //       pointInPixel
  //     );
  //     console.log("pointInGrid[0] : ", pointInGrid[0]);
  //     const xIndex = Math.round(pointInGrid[0]);
  //     console.log("indexindex : ", index);
  //     console.log("xIndex : ", xIndex);
  //     console.log("option : ", option);
  //     const yValue =
  //       index !== "불량" ? option.series[0] : option.series.data[xIndex];
  //     console.log("yValue : ", yValue);
  //     const yIndex = Math.round(pointInGrid[1]);
  //     console.log("yIndex : ", yIndex);
  //     if (yValue !== undefined && yIndex >= 0) {
  //       console.log("inner if");
  //       const yAxisName =
  //         index === "융합"
  //           ? yValue.name
  //           : Array.isArray(option.yAxis) && option.yAxis.length > 0
  //           ? option.yAxis[0]?.data[yIndex]
  //           : option.yAxis?.data[yIndex];
  //       const clickedItem = assetData.find(
  //         (item) => item.asset_name === yAxisName
  //       );
  //       if (clickedItem) {
  //         const { mchId, asset_floor } = clickedItem;
  //         console.log(mchId, asset_floor);
  //         sendMessage("Canvas", "SetFloorVisible", asset_floor);
  //         sendMessage("Canvas", "SetCameraMachineView", mchId);
  //       }
  //     }
  //   }
  // };
  const { lineWorkerData } = useDataLineWorkerData();
  const setupClickHandler = (chart, option) => {
    chartInstance = chart;
    chart.off("click");
    chart.on("click", (params) => handleChartClick(params, option));
  };

  const onChartReady = (chart, option) => {
    chart.clear();
    chart.setOption(option, true);
    setupClickHandler(chart, option);
  };
  const handleMatrixClick = (params) => {
    setPopStatus(false);
    setPopShowing("");
    const clickedItem = assetData.find(
      (item) => item.asset_name === params.seriesName
    );
    if (clickedItem) {
      const { mchId, asset_floor } = clickedItem;
      console.log(mchId, asset_floor);
      sendMessage("Canvas", "SetFloorVisible", asset_floor);
      sendMessage("Canvas", "SetCameraMachineView", mchId);
    }
  };
  const onEvents = {
    click: handleChartClick,
  };
  const onMatrixEvents = {
    click: handleMatrixClick,
  };
  const handleMonthClick = (params) => {
    const num = Number(params.name.split("-")[1]) ?? 1;
    if (window.confirm(`${num}월 생산계획 정보로 이동하시겠습니까?`)) {
      dispatch(setMonth(num));
      window.open(`/table?month=${num}`, "_blank");
    }
  };
  const onClickEvt = {
    click: handleMonthClick,
  };
  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      setIs4k(currentWidth >= 3000);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const { workerOptions } = useWorkerData();
  const { workerOptions1, workerOptions2, workerOptions3, workerOptions4 } =
    workerOptions;
  const { expenseOptions } = useMaintenanceResultExpenseData();
  const { six, facility, expense, sixEach } = expenseOptions;
  const { riskOptions } = useRiskMaintenance();
  const { options2, options3, options4, options1 } = riskOptions;
  const { inspectionOptions } = useDataInspection();
  const {
    inspectionOption1,
    inspectionOption2,
    inspectionOption3,
    inspectionOption4,
  } = inspectionOptions;
  const { brokenOptions } = useBrokenData();
  const {
    brokenOptions1,
    brokenOptions2,
    brokenMatrix,
    brokenOptions4,
    top3Options1,
    top3Options2,
    top3Options3,
  } = brokenOptions;
  const { oldOptions, assetData } = useMasterAssetDataForGraph();
  const { proOptions } = useProductivityData();
  const { proOptions1, proOptions3, proOptions2, proOptions4 } = proOptions;
  const { fusion1, fusion2, fusion3, fusion4 } = useFusionMatrix();
  const { failData } = useFailureData();
  const { fail1, fail2, fail3, fail4 } = failData;
  const { capOpt } = useCapability();
  const { capOpt1, capOpt2, capOpt3, capOpt4 } = capOpt;
  const { productionMonthOpt } = useProductionPlayMonthly();
  const { monthOption1, monthOption2, monthOption3, monthOption4 } =
    productionMonthOpt;
  const non4KWidth = `${String(Number(window.innerWidth) * 0.23)}px`;
  const non4Kheight = `${String(Number(window.innerHeight) * 0.32)}px`;
  useEffect(() => {
    if (chartsRef.current) {
      chartsRef.current.style.transform = `translateX(-${
        currentIndex * 435
      }px)`;
    }
  }, [currentIndex]);
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 1 ? 0 : prevIndex + 1));
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? 1 : prevIndex - 1));
  };
  const chartOptions = {
    "설비 노후화율": [
      { option: oldOptions.option1 || {}, events: null },
      { option: oldOptions.option2 || {}, events: onEvents },
      { option: oldOptions.option3 || {}, events: onEvents },
    ],
    고장도수율: [
      { option: brokenOptions1 || {}, events: null },
      { option: brokenOptions2 || {}, events: null },
      { option: brokenMatrix || {}, events: onMatrixEvents },
      { option: brokenOptions4 || {}, events: null },
    ],
    TOP3: [
      { option: top3Options1 || {}, events: onEvents },
      { option: top3Options2 || {}, events: onEvents },
      { option: top3Options3 || {}, events: onEvents },
    ],
    일상점검율: [
      { option: inspectionOption1 || {}, events: null },
      { option: inspectionOption2 || {}, events: null },
      { option: inspectionOption3 || {}, events: onEvents },
      { option: inspectionOption4 || {}, events: onEvents },
    ],
    보전계획준수율: [
      { option: six || {}, events: null },
      { option: facility || {}, events: null },
      { option: expense || {}, events: null },
      { option: sixEach || {}, events: null },
    ],
    "위험성평가 총건수": [
      { option: options1 || {}, events: null },
      { option: options2 || {}, events: null },
      { option: options3 || {}, events: null },
      { option: options4 || {}, events: null },
    ],
    생산성: [
      { option: proOptions1 || {}, events: null },
      { option: proOptions2 || {}, events: null },
      { option: proOptions3 || {}, events: onClickEvt },
      { option: proOptions4 || {}, events: onEvents },
    ],
    융합: [
      { option: fusion1 || {}, events: onMatrixEvents },
      { option: fusion2 || {}, events: onMatrixEvents },
      { option: fusion3 || {}, events: onMatrixEvents },
      { option: fusion4 || {}, events: onMatrixEvents },
    ],
    작업자: [
      { option: workerOptions1 || {}, events: null },
      { option: workerOptions2 || {}, events: null },
      { option: workerOptions3 || {}, events: null },
      { option: workerOptions4 || {}, events: null },
    ],
    불량: [
      { option: fail1 || {}, events: null },
      { option: fail2 || {}, events: null },
      { option: fail3 || {}, events: onEvents },
      { option: fail4 || {}, events: null },
    ],
    공정능력: [
      { option: capOpt1 || {}, events: null },
      { option: capOpt2 || {}, events: null },
      { option: capOpt3 || {}, events: null },
      { option: capOpt4 || {}, events: null },
    ],
    "10월": [
      { option: monthOption1 || {}, events: null },
      { option: monthOption2 || {}, events: null },
      { option: monthOption3 || {}, events: null },
      { option: monthOption4 || {}, events: null },
    ],
  };

  const renderChart = ({ option, events }, idx) => {
    return (
      <ECharts
        key={`${index}-${idx}`}
        option={option}
        opts={{
          renderer: "svg",
          width: is4k ? "900px" : non4KWidth,
          height: is4k ? "500px" : non4Kheight,
        }}
        onEvents={events}
        onChartReady={events ? (chart) => onChartReady(chart, option) : null}
      />
    );
  };
  const renderCharts = () => {
    const options = chartOptions[index] || [];
    return isUnityChattingOpend ? (
      <s.StyledCarousel>
        <s.PrevBtn onClick={handlePrev}>&#8249;</s.PrevBtn>
        <s.CardsContainer $isUnityChattingOpend={isUnityChattingOpend}>
          <s.Cards ref={chartsRef}>
            {options.map((option, idx) => renderChart(option, idx))}
          </s.Cards>
        </s.CardsContainer>
        <s.NextBtn onClick={handleNext}>&#8250;</s.NextBtn>
      </s.StyledCarousel>
    ) : (
      <>{options.map((option, idx) => renderChart(option, idx))}</>
    );
  };

  return <>{renderCharts()}</>;
}

export default Chart;

const icons = {
  settingsIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><g fill="currentColor" class="nc-icon-wrapper"><path d="M14.25,2H3.75c-1.517,0-2.75,1.233-2.75,2.75V13.25c0,1.517,1.233,2.75,2.75,2.75h4.762c.414,0,.75-.336,.75-.75s-.336-.75-.75-.75H3.75c-.689,0-1.25-.561-1.25-1.25v-5.25H15.5v.984c0,.414,.336,.75,.75,.75s.75-.336,.75-.75V4.75c0-1.517-1.233-2.75-2.75-2.75ZM4,6c-.552,0-1-.448-1-1s.448-1,1-1,1,.448,1,1-.448,1-1,1Zm3,0c-.552,0-1-.448-1-1s.448-1,1-1,1,.448,1,1-.448,1-1,1Z"></path><path d="M17,13h-.878c-.044-.138-.098-.271-.164-.397l.62-.621c.293-.293,.293-.768,0-1.061-.294-.293-.769-.292-1.061,0l-.62,.621c-.127-.066-.259-.121-.397-.164v-.878c0-.414-.336-.75-.75-.75s-.75,.336-.75,.75v.878c-.138,.044-.271,.098-.397,.164l-.62-.621c-.292-.292-.767-.293-1.061,0-.293,.292-.293,.768,0,1.061l.62,.621c-.066,.127-.12,.259-.164,.397h-.878c-.414,0-.75,.336-.75,.75s.336,.75,.75,.75h.878c.044,.138,.098,.271,.164,.397l-.62,.621c-.293,.293-.293,.768,0,1.061,.146,.146,.339,.22,.53,.22,.192,0,.384-.073,.53-.22l.62-.621c.127,.066,.259,.121,.397,.164v.878c0,.414,.336,.75,.75,.75s.75-.336,.75-.75v-.878c.138-.044,.271-.098,.397-.164l.62,.621c.146,.146,.338,.22,.53,.22,.191,0,.384-.073,.53-.22,.293-.292,.293-.768,0-1.061l-.62-.621c.066-.127,.12-.259,.164-.397h.878c.414,0,.75-.336,.75-.75s-.336-.75-.75-.75Zm-2.25,.75c0,.551-.448,1-1,1s-1-.449-1-1,.448-1,1-1,1,.449,1,1Z" data-color="color-2"></path></g></svg>
  ),
  chartIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g fill="currentColor" class="nc-icon-wrapper"> <path d="M1 33V37C1 39.2091 2.79086 41 5 41H43C45.2091 41 47 39.2091 47 37V33H32V35C32 35.5523 31.5523 36 31 36H17C16.4477 36 16 35.5523 16 35V33H1Z" fill="currentColor"></path> <path d="M9.5 5C5.91015 5 3 7.91015 3 11.5V30H6V11.5C6 9.567 7.567 8 9.5 8H22C22 9.10457 22.8954 10 24 10C25.1046 10 26 9.10457 26 8H38.5C40.433 8 42 9.567 42 11.5V30H45V11.5C45 7.91015 42.0899 5 38.5 5H9.5Z" fill="currentColor"></path> <path d="M21 30V13H27V30H21Z" fill="currentColor" data-color="color-2"></path> <path d="M31 30V18H37V30H31Z" fill="currentColor" data-color="color-2"></path> <path d="M11 30V24H17V30H11Z" fill="currentColor" data-color="color-2"></path> </g></svg>
  ),
  kpiIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="currentColor" class="nc-icon-wrapper"><path d="M16,3A15.981,15.981,0,0,0,3.221,28.6a1,1,0,0,0,.8.4H27.98a1,1,0,0,0,.8-.4A15.857,15.857,0,0,0,32,19,16.019,16.019,0,0,0,16,3ZM15,6.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v3a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5Zm-8,13a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5Zm10.414.914a2,2,0,0,1-2.828,0c-.592-.593-6.78-8.689-7.483-9.61a.5.5,0,0,1,.7-.7c.922.7,9.019,6.89,9.611,7.483A2,2,0,0,1,17.414,20.414Zm5.3-7.425-.707-.707a.5.5,0,0,1,0-.707l2.122-2.121a.5.5,0,0,1,.707,0l.707.707a.5.5,0,0,1,0,.707l-2.122,2.121A.5.5,0,0,1,22.717,12.989ZM28.5,20h-3a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,28.5,20Z" fill="currentColor"></path></g></svg>
  ),
  logoutIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g stroke-linejoin="round" fill="currentColor" stroke-linecap="round" class="nc-icon-wrapper"><polyline points="18.5 6 22.5 10 18.5 14" fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" data-color="color-2"></polyline><polyline points="14 14 14 17 9 17" fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"></polyline><polyline points="2.722 2.517 9 7.016 9 22 2 17 2 2 14 2 14 6" fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"></polyline><polyline points="13 10 22.5 10 22 10" fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" data-color="color-2"></polyline></g></svg>
  ),
  qrIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g stroke-linecap="round" transform="translate(0.5 0.5)" fill="currentColor" stroke-linejoin="round" class="nc-icon-wrapper"><polygon fill="none" stroke="currentColor" stroke-width="3" points="18,18 2,18 2,2 18,2 18,2 "></polygon> <polyline fill="none" stroke="currentColor" stroke-width="3" points="46,38 46,30 36,30 36,27 "></polyline> <line fill="none" stroke="currentColor" stroke-width="3" x1="46" y1="46" x2="30" y2="46"></line> <polygon data-color="color-2" fill="none" stroke="currentColor" stroke-width="3" points=" 12,12 8,12 8,8 12,8 12,12 "></polygon> <polygon fill="none" stroke="currentColor" stroke-width="3" points="46,18 30,18 30,2 46,2 46,2 "></polygon> <polygon data-color="color-2" fill="none" stroke="currentColor" stroke-width="3" points=" 40,12 36,12 36,8 40,8 40,12 "></polygon> <polygon fill="none" stroke="currentColor" stroke-width="3" points="18,46 2,46 2,30 18,30 18,30 "></polygon> <polygon data-color="color-2" fill="none" stroke="currentColor" stroke-width="3" points=" 12,40 8,40 8,36 12,36 12,40 "></polygon> <polygon data-color="color-2" fill="none" stroke="currentColor" stroke-width="3" points=" 40,40 36,40 36,36 40,36 40,40 "></polygon> <polyline fill="none" stroke="currentColor" stroke-width="3" points="25,41 25,37 29,37 29,27 "></polyline> <polyline fill="none" stroke="currentColor" stroke-width="3" points="17,25 17,23 24,23 24,27 "></polyline> <polyline fill="none" stroke="currentColor" stroke-width="3" points="22,11 25,11 25,6 "></polyline> <line fill="none" stroke="currentColor" stroke-width="3" x1="43" y1="24" x2="43" y2="30"></line> <polyline fill="none" stroke="currentColor" stroke-width="3" points="2,22 2,24 8,24 "></polyline></g></svg>
  ),
  repairIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g stroke-linecap="round" transform="translate(0.5 0.5)" fill="currentColor" stroke-linejoin="round" class="nc-icon-wrapper"> <path d="M15 15L20.5 20.5" stroke="currentColor" stroke-width="3" data-color="color-2" fill="none"></path> <path d="M23.6262 17.6729L4.83678 35.07C2.45141 37.2784 2.37998 41.0254 4.67756 43.323C6.97514 45.6206 10.7221 45.5477 12.9304 43.1638L30.3379 24.3625" stroke="currentColor" stroke-width="3" fill="none" data-cap="butt"></path> <path d="M27.3806 34.8563L35.0696 43.1609C37.2779 45.5449 41.0249 45.6178 43.3224 43.3202C45.62 41.0225 45.5486 37.2755 43.1632 35.0672L37.2767 29.6168" stroke="currentColor" stroke-width="3" data-color="color-2" fill="none"></path> <path d="M37.9286 16.3571L31.6429 10.0714L37.9663 3.748C36.7343 3.27186 35.4001 3 34 3C27.9249 3 23 7.92486 23 14C23 20.0751 27.9249 25 34 25C40.0751 25 45 20.0751 45 14C45 12.5999 44.7281 11.2657 44.252 10.0337L37.9286 16.3571Z" stroke="currentColor" stroke-width="3" fill="none"></path> <path d="M30 16L32 18" stroke="currentColor" stroke-width="3" fill="none"></path> <path d="M37 37L39.5 39.5" stroke="currentColor" stroke-width="3" data-color="color-2" fill="none"></path> <path d="M3 6L6 3L15 7.5V15H7.5L3 6Z" stroke="currentColor" stroke-width="3" data-color="color-2" fill="none"></path> </g></svg>
  ),
  checklistIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g stroke-linecap="round" transform="translate(0.5 0.5)" fill="currentColor" stroke-linejoin="round" class="nc-icon-wrapper"><polygon fill="none" stroke="currentColor" stroke-width="3" points="6,2 6,2 42,2 42,46 6,46 "></polygon> <line data-color="color-2" fill="none" stroke="currentColor" stroke-width="3" x1="35" y1="12" x2="25" y2="12"></line> <line data-color="color-2" fill="none" stroke="currentColor" stroke-width="3" x1="35" y1="24" x2="25" y2="24"></line> <line data-color="color-2" fill="none" stroke="currentColor" stroke-width="3" x1="35" y1="36" x2="25" y2="36"></line> <polyline data-color="color-2" fill="none" stroke="currentColor" stroke-width="3" points=" 13,12 15,14 20,9 "></polyline> <polyline data-color="color-2" fill="none" stroke="currentColor" stroke-width="3" points=" 13,24 15,26 20,21 "></polyline> <circle data-color="color-2" fill="none" stroke="currentColor" stroke-width="3" cx="16" cy="36" r="3"></circle></g></svg>
  ),
  cctvIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="currentColor" class="nc-icon-wrapper"><path data-color="color-2" fill="currentColor" d="M32 30H22a1 1 0 0 1-.986-.835l-1.164-6.986 1.973-.329L22.847 28H32v2z"></path><path fill="currentColor" d="M.032 8.77L2 8.402l2.385 12.81-1.966.366z"></path><path fill="currentColor" d="M29.071 2.923a1.004 1.004 0 0 0-.418-.642.993.993 0 0 0-.748-.158l-23.3 4.339a1.002 1.002 0 0 0-.8 1.166l2.524 13.555a1.001 1.001 0 0 0 1.167.8l23.299-4.338c.543-.101.901-.623.801-1.166L29.071 2.923zM11.5 13a1.5 1.5 0 1 1 .001-3.001A1.5 1.5 0 0 1 11.5 13z"></path></g></svg>
  ),
  warningIcon: (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="currentColor" class="nc-icon-wrapper"><path d="m30.464,23L19.464,3.948c-.723-1.252-2.018-2-3.464-2s-2.741.748-3.464,2L1.536,23c-.723,1.252-.723,2.748,0,4,.723,1.252,2.018,2,3.464,2h21.998c1.446,0,2.741-.748,3.464-2,.724-1.252.724-2.748,0-4Zm-15.464-14h2v10h-2v-10Zm1,15c-.827,0-1.5-.673-1.5-1.5s.673-1.5,1.5-1.5,1.5.673,1.5,1.5-.673,1.5-1.5,1.5Z" stroke-width="0" fill="currentColor"></path></g></svg>
  ),
};

export const Icon = ({ name, ...props }) => {
  const SvgIcon = icons[name];
  return SvgIcon ? <SvgIcon {...props} /> : null;
};
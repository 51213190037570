import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

const CustomLinearProgress = styled(LinearProgress)(({ theme, isBroken }) => ({
  height: 20,
  borderRadius: 5,
  padding: 10,
  backgroundColor: '#555',
  boxShadow: 'inset 0 -1px 1px rgba(255, 255, 255, 0.3)',
  '& .MuiLinearProgress-bar': {
    height: '100%',
    borderRadius: 5,
    backgroundColor: isBroken ? '#e53935' : '#1b54ea', // 고장 시 빨간색
    // Stripe 효과는 고장 상태에서도 유지
    backgroundImage: `linear-gradient(
      -45deg, 
      rgba(255, 255, 255, .2) 25%, 
      transparent 25%, 
      transparent 50%, 
      rgba(255, 255, 255, .2) 50%, 
      rgba(255, 255, 255, .2) 75%, 
      transparent 75%, 
      transparent
    )`,
    // 고장 시 애니메이션만 제거
    boxShadow: isBroken
      ? 'inset 0 2px 9px rgba(255, 0, 0, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4)'
      : 'inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4)',
    animation: isBroken ? 'none' : 'progress-bar-stripes 2s linear infinite',
    backgroundSize: `50px 50px`, // stripe 크기 설정
  },
  '@keyframes progress-bar-stripes': {
    '0%': {
      backgroundPosition: '0 0',
    },
    '100%': {
      backgroundPosition: '50px 50px',
    },
  }
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%' }}>
        <CustomLinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="rgb(255, 255, 255)" sx={{ ml: '5px' }}>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function calculateProgress(startTime, endTime) {
  const start = new Date(startTime);
  const end = new Date(endTime);
  const now = new Date();
  const totalDuration = end - start;
  const elapsed = now - start;
  return Math.min((elapsed / totalDuration) * 100, 100);
}

export default function CustomProgressBar({ startTime, endTime, machineStatus, plannedQty, resultQty }) {
  const [progress, setProgress] = useState(0);

  // useEffect(() => {
  //   if (startTime && endTime) {
  //     setProgress(calculateProgress(startTime, endTime));
  //   } else {
  //     setProgress(0);
  //   }
  // }, [startTime, endTime]);

  useEffect(() => {
    if ((plannedQty && resultQty) && plannedQty !== -1 && resultQty !== -1) {
      const progress = ((resultQty / plannedQty) * 100).toFixed(1);
      setProgress(parseFloat(progress));
    } else {
      setProgress(0);
    }
  }, [plannedQty, resultQty]);

  // machineStatus가 9일 경우, 고장 상태로 표시
  const isBroken = machineStatus === 9;

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={progress} isBroken={isBroken} />
    </Box>
  );
}
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid, Card, Typography, CardContent, Collapse, Checkbox, ListItem, CardHeader, IconButton } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/material/styles'; // 스타일과 애니메이션 추가

// 아이콘 회전 애니메이션을 위한 스타일 정의
const ExpandMoreIcon = styled((props) => (
  <IconButton {...props}>
    <ExpandMore />
  </IconButton>
))(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const workProc = [
  "운전 정지",
  "LOTO 설치",
  "LOTO 해제",
  "운전 시작",
];

const safetyPrincipal = [
  "기계설비 주전원 차단",
  "전원부 표지판 설치",
  "전원부 표지판 해제",
  "기계설비 주전원 가동",
];

const ChecklistPage = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState([false, false, false, false]); // 상위 리스트 펼침 상태 관리
  const [checked, setChecked] = useState([false, false, false, false]);  // 체크박스 상태 관리
  const [dates, setDates] = useState([null, null, null, null]); // 각 체크박스가 체크된 날짜 관리

  // Redux에서 선택된 장비 정보 가져오기 (예시)
  const selectedAssetCode = useSelector((state) => state.auth.qrData);

  // 카드 또는 아이콘 클릭 시 펼치기/접기 기능
  const handleExpandClick = (index) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index]; // 클릭한 리스트의 펼침 상태 토글
      return newExpanded;
    });
  };

  // 체크박스 변경 시 체크된 날짜 설정 및 순차적 활성화 처리
  const handleCheckboxChange = (index) => {
    setChecked((prevChecked) => {
      const newChecked = [...prevChecked];
      if (index === 0 || prevChecked[index - 1]) { // 이전 체크박스가 체크된 경우만 다음 체크박스 활성화
        newChecked[index] = !newChecked[index];
        // 체크될 때 현재 날짜와 시간을 설정
        if (newChecked[index]) {
          const currentDate = new Date().toLocaleString(); // 날짜와 시간을 표시
          setDates((prevDates) => {
            const newDates = [...prevDates];
            newDates[index] = currentDate;
            return newDates;
          });
        } else {
          setDates((prevDates) => {
            const newDates = [...prevDates];
            newDates[index] = null; // 체크 해제 시 날짜 제거
            return newDates;
          });
        }
      }
      return newChecked;
    });
  };

  return (
    <Grid container spacing={0} sx={{ p: 4 }}>
      <Grid item xs={12}>
        {/* 상위 리스트 (작업 절차) */}
        {workProc.map((item, index) => (
          <Card key={index} sx={{ mb: '10px' }}>
            {/* 상위 리스트 헤더 (작업 절차) */}
            <CardHeader
              title={item}
              onClick={() => handleExpandClick(index)} // 타이틀 전체 클릭으로 확장/축소 기능 추가
              action={
                <ExpandMoreIcon
                  expand={expanded[index]} // 확장 상태에 따라 아이콘 회전
                  onClick={(event) => {
                    event.stopPropagation(); // 아이콘 클릭 시 이벤트 전파를 막음
                    handleExpandClick(index); // 아이콘 클릭 시에도 확장/축소
                  }}
                />
              }
              sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
              subheader={
                <Typography variant='body2' color='text.secondary'>
                  {/* 체크박스가 체크되지 않으면 "미처리" 텍스트를 표시 */}
                  {dates[index] ? dates[index] : "미처리"}
                </Typography>
              }
            />

            {/* 하위 리스트 (안전수칙) - Collapse로 접힘 상태 구현 */}
            <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
              <CardContent>
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body1">{safetyPrincipal[index]}</Typography>
                  <Checkbox
                    checked={checked[index]}
                    onChange={() => handleCheckboxChange(index)}
                    disabled={index > 0 && !checked[index - 1]} // 이전 체크박스가 체크되지 않았다면 비활성화
                  />
                </ListItem>
              </CardContent>
            </Collapse>
          </Card>
        ))}
      </Grid>
    </Grid>
  );
};

export default ChecklistPage;
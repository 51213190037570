import React from "react";
import * as s from "./styles";
import Chart from "../Graph";
import { cards } from "./constants";
import { useSelector } from "react-redux";
import CardContent from "./CardContent.js";

function SelectedCard({
  selectedCardIndex,
  carouselVisible,
  handleSelectedCardClick,
  chartData,
  fusionData,
  isUnityChattingOpened,
  sendMessage,
  prevCard,
  nextCard,
}) {
  const { chattingStatus } = useSelector((state) => state.auth);
  const isUnityChattingOpend =
    chattingStatus.type === "Meta" && chattingStatus.status === "1";
  const cardContent = cards[selectedCardIndex?.id - 1].content;
  const topPosition = "-" + window.innerHeight * 0.41 - 15 + "px";
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "550px",
          height: "100px",
          position: "absolute",
          top: topPosition,
          left: "-20px",
        }}
      >
        <s.PrevBtnOutside onClick={prevCard}>&#8249;</s.PrevBtnOutside>
        <s.SelectedCard
          onClick={handleSelectedCardClick}
          $carouselVisible={carouselVisible}
          className={
            carouselVisible ? "selected-card-hidden" : "selected-card-visible"
          }
        >
          <CardContent
            cardType={cardContent}
            chartData={chartData}
            fusionData={fusionData}
          />{" "}
        </s.SelectedCard>
        <s.NextBtnOutside onClick={nextCard}>&#8250;</s.NextBtnOutside>
      </div>
      <s.RedBox
        $isUnityChattingOpend={isUnityChattingOpened}
        $carouselVisible={carouselVisible}
        className={carouselVisible ? "red-box-hidden" : "red-box-visible"}
      >
        <Chart
          index={cards[selectedCardIndex?.id - 1].content}
          sendMessage={sendMessage}
          isUnityChattingOpend={isUnityChattingOpend}
        />
      </s.RedBox>
    </>
  );
}

export default SelectedCard;

import styled, { keyframes } from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
export const Overlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  width: 80%;
  height: 70%;
  border-radius: 30px;
`;

export const GanttOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(30, 5, 41, 0.6);
  width: 75%;
  height: 56%;
  border-radius: 8px;
  padding: 20px;
`;

export const SummaryOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(30, 5, 41, 0.6);
  height: ${(props) =>
    props.$isSummayLine
      ? "455px"
      : props.$isDaily || props.$isWorkerTable
      ? "56%"
      : "none"};
  border-radius: 8px;
  padding: 20px;
`;

export const ChartBox = styled.div`
  width: 100%;
  height: 80%;
`;

export const CloseBtn = styled(CloseIcon)`
  position: absolute;
  top: 20px;
  right: 50px;
  color: white;
  cursor: pointer;
  font-size: 4rem;
  z-index: 99999999;
`;

export const SummaryCloseBtn = styled(CloseIcon)`
  color: white;
  cursor: pointer;
  font-size: 2rem;
  z-index: 99999999;
  &:hover {
    scale: 1.2;
  }
`;

export const Dim = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
`;
const fadeIn = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
const fadeOutCarousel = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100px);
  }
`;
const dropDownCarousel = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Carousel = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  animation: ${(props) => (props.$carouselVisible ? fadeIn : fadeOut)} 0.5s
    ease-in-out;
`;
export const StyledCarousel = styled(Carousel)`
  display: flex;
  width: calc(100vw - 500px);
  align-items: center;
  position: relative;
  animation: ${(props) =>
      props.$animating
        ? props.$carouselVisible
          ? dropDownCarousel
          : fadeOutCarousel
        : props.$carouselVisible
        ? fadeIn
        : fadeOut}
    0.5s ease-in-out;
`;
export const CarouselContainer = styled.div`
  position: fixed;
  bottom: 10px;
  left: 100px;
  width: ${(props) => (props.$isUnityChattingOpend ? "80%" : "97%")};
  // width: 80%;
  // width: 97%;
`;

export const PrevBtn = styled.button`
  background-color: transparent;
  border: none;
  font-size: 5rem;
  left: 10px;
  color: #dee2e6;
  position: absolute;
  cursor: pointer;
  margin-right: 10px;
  &:hover {
    color: white;
  }
`;

export const NextBtn = styled.button`
  background-color: transparent;
  border: none;
  font-size: 5rem;
  color: #dee2e6;
  cursor: pointer;
  margin-left: 10px;
  position: absolute;
  right: 10px;
  z-index: 999999;
  &:hover {
    color: white;
  }
`;
export const CardsContainer = styled.div`
  margin-left: 40px;
  overflow: hidden;
  width: ${(props) => (props.$isUnityChattingOpend ? "88%" : "92%")};
  //  width: 92%;
  //95%
`;

export const Cards = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 120%;
`;

export const Card = styled.div`
  flex: ${(props) =>
    props.$isUnityChattingOpend ? "0 0 49.3%" : "0 0 32.76%"};
  // background-color: rgba(52, 58, 64, 0.5);
  border-radius: 15px;
  margin-right: 10px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 3000px) {
    height: 270px;
  }
`;

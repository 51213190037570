import React from 'react';

function Todo(props) {
	return (
		<svg height="22" width="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
	<g fill="#A0AEC0" strokeLinecap="butt" strokeLinejoin="miter">
		<path d="M8.28 13.11l2.07 2.07 4.14-4.14" fill="none" stroke="#A0AEC0" strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="2"/>
		<path d="M13.11 2.76a2.07 2.07 0 1 0-4.14 0h-2.07v2.76h8.28v-2.76h-2.07z" fill="none" stroke="#A0AEC0" strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="2"/>
		<path d="M17.25 3.45h2.07v17.94h-16.56v-17.94h2.07" fill="none" stroke="#A0AEC0" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="2"/>
	</g>
</svg>
	);
};

export default Todo;
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';

// Draggable Paper component
const PaperComponent = (props) => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

const BrokenImageDialog = ({ open, onClose, imageUrl, assetInfo }) => {
  const handleDialogClose = (event) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        hideBackdrop={true}
        PaperComponent={PaperComponent}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: 'rgba(47, 51, 73, 0.8)',
            border: 'solid 2px rgb(63 68 99)',
          },
        }}
      >
        <DialogTitle
          id="draggable-dialog-title"
          sx={{
            borderBottom: 'solid 1px #464646',
            cursor: 'move',
          }}
        >
          <Typography variant="h3" sx={{ mb: 3, color: '#ced4da' }}>
            고장사진 - {assetInfo}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: '1.5rem !important'
          }}
        >
          <Box>
            <img src={imageUrl} alt="고장 이미지" width="300px" />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BrokenImageDialog;
import React from "react";
import * as s from "./styles";
import Card from "./Card";

function CarouselCard({
  cardsRef,
  cards,
  handleCardClick,
  carouselVisible,
  animating,
  isUnityChattingOpened,
  prevCard,
  nextCard,
}) {
  return (
    <s.StyledCarousel
      $carouselVisible={carouselVisible}
      $animating={animating}
      className={
        carouselVisible
          ? "carousel-visible"
          : animating
          ? "carousel-hiding"
          : "carousel-hidden"
      }
      $isUnityChattingOpend={isUnityChattingOpened}
    >
      <s.PrevBtn onClick={prevCard}>&#8249;</s.PrevBtn>
      <Card
        cardsRef={cardsRef}
        cards={cards}
        handleCardClick={handleCardClick}
      />
      <s.NextBtn onClick={nextCard}>&#8250;</s.NextBtn>
    </s.StyledCarousel>
  );
}

export default CarouselCard;

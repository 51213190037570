import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Card, Grid, Divider, Button, CardHeader, CardContent, Typography } from '@mui/material';
import CustomAvatar from "../../mui/avartar";
import { Icon } from '../../icons';
import axios from 'axios';

const QrPage = () => {

  const navigate = useNavigate();
  // Redux에서 user 정보 가져오기
  const user = useSelector((state) => state.auth.user);

  const handleCardClick = (path) => {
    navigate(`/mobile/${path}`);
  };

  return (
    <Grid container spacing={0} sx={{ overflowY: 'auto', p: 4 }}>
      <Grid item xs={12}>
        {/* QR 코드 장비 선택 카드 */}
        <Card sx={{ mb: '10px', cursor: 'pointer' }} onClick={() => handleCardClick('qrscan')}>
          <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
            <CustomAvatar
              skin="light"
              color="primary"
              sx={{ width: 40, height: 40, mb: 2 }}
              variant="rounded"
            >
              <Icon 
                name="qrIcon"
                style={{ 
                  width: "24px", 
                  height: "24px",
                }}
              />
            </CustomAvatar>
            <Typography variant='h5'>QR 코드 스캔</Typography>
          </CardContent>
        </Card>

        {/* 작업안전수칙 카드 */}
        <Card sx={{ mb: '10px', cursor: 'pointer' }} onClick={() => handleCardClick('checklist')}>
          <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
            <CustomAvatar
              skin="light"
              color="primary"
              sx={{ width: 40, height: 40, mb: 2 }}
              variant="rounded"
            >
              <Icon 
                name="checklistIcon"
                style={{ 
                  width: "24px", 
                  height: "24px",
                }}
              />
            </CustomAvatar>
            <Typography variant='h5'>장비 리스트 선택</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default QrPage;
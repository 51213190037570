import { useState, useEffect } from "react";
import axios from "axios";
import API_URL from "../utils/API_URL";
import { calculateYearsFromDate } from "../utils/graphUtilFunction";
import { useSelector, useDispatch } from "react-redux";
import { setPopShowing, setPopStatus } from "../slice/authSlice";
import {
  createBarChartOptions,
  createHorizontalBarChartOptions,
} from "../utils/chartConfig";

const processAssetData = (data) => {
  return data
    .map((item) => ({
      ...item,
      asset_used: calculateYearsFromDate(item.asset_purchase),
      asset_aging:
        item.usable - calculateYearsFromDate(item.asset_purchase) > 0 ? 0 : 1,
    }))
    .sort((a, b) => a.asset_used - b.asset_used);
};

const calculateGraph1Data = (sortedData) => {
  const graph1XAxis = [];
  const graph1Series = [];
  const groupSet = [...new Set(sortedData.map((item) => item.asset_group))];
  groupSet.forEach((group) => {
    const target = sortedData.filter((item) => item.asset_group === group);
    const targetGradeSet = [
      ...new Set(target.map((item) => item.asset_grade)),
    ].sort();
    targetGradeSet.forEach((grade) => {
      const realTarget = sortedData.filter(
        (item) => item.asset_grade === grade && item.asset_group === group
      );
      graph1XAxis.push(`${grade}\n${group}`);
      const isOld =
        (realTarget.filter((item) => item.asset_usable < item.asset_used)
          .length /
          realTarget.length) *
        100;
      graph1Series.push(isOld);
    });
  });
  return { graph1XAxis, graph1Series };
};

const calculateGraph3Data = (sortedData) => {
  const buildingYear = 40;
  return sortedData
    .map((item) => ({
      ...item,
      rate: (
        (item.asset_used /
          (!item.asset_usable ? buildingYear : item.asset_usable)) *
        100
      ).toFixed(1),
    }))
    .sort((a, b) => a.rate - b.rate);
};

export const useMasterAssetDataForGraph = () => {
  const dispatch = useDispatch();
  const [assetData, setAssetData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [oldTitleConfig, setOldTitleConfig] = useState([]);
  const [oldOptions, setOldOptions] = useState({});
  const [bigOld, setBigOld] = useState({});
  const popStatus = useSelector((state) => state.auth.popStatus);

  const handleOverlay = (param) => {
    dispatch(setPopStatus(!popStatus));
    dispatch(setPopShowing(param));
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const res = await axios.get(API_URL + "/data/getMasterAssetForGraph");
        const sortedData = processAssetData(res.data);
        setAssetData(res.data);
        const { graph1XAxis, graph1Series } = calculateGraph1Data(sortedData);
        const graph3data = calculateGraph3Data(sortedData);
        const newOldOptions = {
          option1: createBarChartOptions(
            "설비 노후화율(%)",
            graph1XAxis,
            [
              {
                data: graph1Series,
                name: "노후화율",
                type: "bar",
                color: "#6A60A9",
              },
            ],
            { type: "value", show: false },
            () => handleOverlay("old1")
          ),
          option2: createHorizontalBarChartOptions(
            "설비별 사용연수(년)",
            sortedData.map((item) => item.asset_name),
            [
              {
                data: sortedData.map((item) =>
                  calculateYearsFromDate(item.asset_purchase)
                ),
                type: "bar",
                color: "#6A60A9",
              },
            ],
            { type: "value", show: false },
            () => handleOverlay("old2"),
            false,
            false,
            true
          ),
          option3: createHorizontalBarChartOptions(
            "감가상각률(%)",
            graph3data.map((item) => item.asset_name),
            [
              {
                data: graph3data.map((item) => item.rate),
                type: "bar",
                color: "#6A60A9",
              },
            ],
            { type: "value", show: false },
            () => handleOverlay("old3"),
            false,
            false,
            true
          ),
        };

        const newBigOld = {
          old2: createHorizontalBarChartOptions(
            "설비별 사용연수(년)",
            sortedData.map((item) => item.asset_name),
            [
              {
                data: sortedData.map((item) =>
                  calculateYearsFromDate(item.asset_purchase)
                ),
                type: "bar",
                color: "#6A60A9",
              },
            ],
            { type: "value", show: false },
            handleOverlay,
            true
          ),
          old3: createHorizontalBarChartOptions(
            "감가상각률(%)",
            graph3data.map((item) => item.asset_name),
            [
              {
                data: graph3data.map((item) => item.rate),
                type: "bar",
                color: "#6A60A9",
              },
            ],
            { type: "value", show: false },
            handleOverlay,
            true
          ),
          old1: createBarChartOptions(
            "설비 노후화율(%)",
            graph1XAxis,
            [
              {
                data: graph1Series,
                name: "노후화율",
                type: "bar",
                color: "#6A60A9",
              },
            ],
            { type: "value", show: false },
            handleOverlay,
            true
          ),
        };

        const oldRate = (
          graph1Series.reduce((acc, cur) => acc + cur) / graph1Series.length
        ).toFixed(1);
        const averageUsedAge =
          sortedData.reduce((acc, cur) => acc + cur.asset_used, 0) /
          sortedData.length;
        setOldTitleConfig([
          {
            id: 1,
            label: "설비노후화율",
            value: `${oldRate}%`,
            color: "#ffec99",
          },
          {
            id: 2,
            label: "평균 설비사용연수",
            value: Math.floor(averageUsedAge),
            color: "#99e9f2",
          },
        ]);
        setOldOptions(newOldOptions);
        setBigOld(newBigOld);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    }
    fetchData();
  }, []);

  return {
    assetData,
    loading,
    oldOptions,
    oldTitleConfig,
    bigOld,
  };
};

import React, { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  sidebarClasses,
  menuClasses,
} from "react-pro-sidebar";
import FactoryIcon from "@mui/icons-material/Factory";
import ConstructionIcon from "@mui/icons-material/Construction";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AssessmentIcon from "@mui/icons-material/Assessment";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import WaterfallChartIcon from "@mui/icons-material/WaterfallChart";
import {
  setKpiStatus,
  setGraphStatus,
  setGanttStatus,
  setSummaryItemStatus,
  setSummaryLineStatus,
  setSummaryResultStatus,
  setLineSummaryStatus,
  setSimResult,
  setPlanWeek,
  setSimulation1,
  setSimulation2,
  setWorkerStatus,
} from "../../slice/authSlice";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import * as s from "./styles";
import AutomatedLogistics from "../NucleoIcons/icons/automated-logistics";
import Todo from "../NucleoIcons/icons/todo";
import UserConstructionWorker from "../NucleoIcons/icons/user-construction-worker";

function SideBar({ unload }) {
  const [collapsed, setCollapsed] = useState(true);
  const { logout } = useContext(AuthContext);
  const graphStatus = useSelector((state) => state.auth.graphStatus);
  const kpiStatus = useSelector((state) => state.auth.kpiStatus);
  const ganttStatus = useSelector((state) => state.auth.ganttStatus);
  const summaryItemStatus = useSelector(
    (state) => state.auth.summaryItemStatus
  );
  const summaryLineStatus = useSelector(
    (state) => state.auth.summaryLineStatus
  );
  const summaryResultStatus = useSelector(
    (state) => state.auth.summaryResultStatus
  );
  const lineSummaryStatus = useSelector(
    (state) => state.auth.lineSummaryStatus
  );
  const simResult = useSelector((state) => state.auth.simResult);
  const planWeek = useSelector((state) => state.auth.planWeek);
  const simulation1 = useSelector((state) => state.auth.simulation1);
  const simulation2 = useSelector((state) => state.auth.simulation2);
  const workerStatus = useSelector((state) => state.auth.workerStatus);
  const dispatch = useDispatch();
  const handleGraphStatus = () => {
    dispatch(setGraphStatus(!graphStatus));
  };
  const handleKpiCardStatus = () => {
    dispatch(setKpiStatus(!kpiStatus));
  };
  const handleMenuStatus = (param) => {
    switch (param) {
      case "gantt":
        dispatch(setGanttStatus(!ganttStatus));
        dispatch(setSummaryItemStatus(false));
        dispatch(setSummaryResultStatus(false));
        dispatch(setLineSummaryStatus(false));
        dispatch(setSimResult(false));
        dispatch(setPlanWeek(false));
        dispatch(setSimulation1(false));
        dispatch(setSimulation2(false));
        dispatch(setWorkerStatus(false));
        break;
      case "item":
        dispatch(setGanttStatus(false));
        dispatch(setSummaryItemStatus(!summaryItemStatus));
        dispatch(setSummaryResultStatus(false));
        dispatch(setLineSummaryStatus(false));
        dispatch(setSimResult(false));
        dispatch(setPlanWeek(false));
        dispatch(setSimulation1(false));
        dispatch(setSimulation2(false));
        dispatch(setWorkerStatus(false));
        break;
      case "result":
        dispatch(setGanttStatus(false));
        dispatch(setSummaryItemStatus(false));
        dispatch(setSummaryResultStatus(!summaryResultStatus));
        dispatch(setLineSummaryStatus(false));
        dispatch(setSimResult(false));
        dispatch(setPlanWeek(false));
        dispatch(setSimulation1(false));
        dispatch(setSimulation2(false));
        dispatch(setWorkerStatus(false));
        break;
      case "lineSummary":
        dispatch(setLineSummaryStatus(!lineSummaryStatus));
        dispatch(setGanttStatus(false));
        dispatch(setSummaryItemStatus(false));
        dispatch(setSummaryResultStatus(false));
        dispatch(setSimResult(false));
        dispatch(setPlanWeek(false));
        dispatch(setSimulation1(false));
        dispatch(setSimulation2(false));
        dispatch(setWorkerStatus(false));
        break;
      case "simResult":
        dispatch(setSimResult(!simResult));
        dispatch(setGanttStatus(false));
        dispatch(setSummaryItemStatus(false));
        dispatch(setSummaryResultStatus(false));
        dispatch(setLineSummaryStatus(false));
        dispatch(setPlanWeek(false));
        dispatch(setSimulation1(false));
        dispatch(setSimulation2(false));
        dispatch(setWorkerStatus(false));
        break;
      case "week":
        dispatch(setSimResult(false));
        dispatch(setGanttStatus(false));
        dispatch(setSummaryItemStatus(false));
        dispatch(setSummaryResultStatus(false));
        dispatch(setLineSummaryStatus(false));
        dispatch(setPlanWeek(!planWeek));
        dispatch(setSimulation1(false));
        dispatch(setSimulation2(false));
        dispatch(setWorkerStatus(false));
        break;
      case "simulation1":
        dispatch(setSimResult(false));
        dispatch(setGanttStatus(false));
        dispatch(setSummaryItemStatus(false));
        dispatch(setSummaryResultStatus(false));
        dispatch(setLineSummaryStatus(false));
        dispatch(setPlanWeek(false));
        dispatch(setSimulation1(!simulation1));
        dispatch(setSimulation2(false));
        dispatch(setWorkerStatus(false));
        break;
      case "simulation2":
        dispatch(setSimResult(false));
        dispatch(setGanttStatus(false));
        dispatch(setSummaryItemStatus(false));
        dispatch(setSummaryResultStatus(false));
        dispatch(setLineSummaryStatus(false));
        dispatch(setPlanWeek(false));
        dispatch(setSimulation1(false));
        dispatch(setSimulation2(!simulation2));
        dispatch(setWorkerStatus(false));
        break;
      case "worker":
        dispatch(setSimResult(false));
        dispatch(setGanttStatus(false));
        dispatch(setSummaryItemStatus(false));
        dispatch(setSummaryResultStatus(false));
        dispatch(setLineSummaryStatus(false));
        dispatch(setPlanWeek(false));
        dispatch(setSimulation1(false));
        dispatch(setSimulation2(false));
        dispatch(setWorkerStatus(!workerStatus));
        break;
      default:
        // 모든 상태를 false로 설정
        dispatch(setGanttStatus(false));
        dispatch(setSummaryItemStatus(false));
        dispatch(setSummaryResultStatus(false));
        dispatch(setLineSummaryStatus(false));
        dispatch(setSimResult(false));
        dispatch(setPlanWeek(false));
        dispatch(setSimulation1(false));
        dispatch(setSimulation2(false));
        dispatch(setWorkerStatus(false));
    }
  };
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    if (unload) {
      await unload();
    }
    logout();
    window.location.reload();
  };
  const handleLinkClick = async (path) => {
    if (location.pathname === "/sfv") {
      window.open(path, "_blank");
    } else {
      navigate(path);
    }
  };
  return (
    <Sidebar
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 100,
        height: "100vh",
      }}
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          backgroundColor: "#1a1a1a !important",
          color: "#a0aec0 !important",
        },
        [`.${menuClasses.menuItemRoot}`]: {
          backgroundColor: "#1a1a1a !important",
          color: "#a0aec0 !important",
        },
        [`.${menuClasses.button}`]: {
          "&:hover": {
            color: "#f7fafc !important",
            background: "#343a40 !important",
          },
        },
      }}
      collapsed={collapsed}
      onMouseEnter={() => setCollapsed(false)}
      onMouseLeave={() => setCollapsed(true)}
    >
      <s.TopWrapper>
        <s.TopLogoBox>
          <img
            className="Sidebar_logo"
            src="/images/ese_logo.png"
            alt="logo"
            style={{ width: collapsed ? "60px" : "180px" }}
          />
        </s.TopLogoBox>
        <Menu iconShape="square">
          <MenuItem onClick={() => handleLinkClick("/")} icon={<FactoryIcon />}>
            Insightwin
          </MenuItem>
          <SubMenu
            label="Master"
            title="Components"
            icon={<ConstructionIcon />}
          >
            <MenuItem onClick={() => handleLinkClick("/asset")}>
              설비기준정보
            </MenuItem>
          </SubMenu>
          <SubMenu
            label="생산계획"
            title="Components"
            icon={<AutomatedLogistics />}
          >
            <MenuItem onClick={() => handleMenuStatus("week")}>
              주간생산계획
            </MenuItem>
            <MenuItem onClick={() => handleMenuStatus("item")}>
              일일생산계획/실적
            </MenuItem>
            <MenuItem onClick={() => handleMenuStatus("gantt")}>
              일일생산계획(Gantt)
            </MenuItem>
            <MenuItem onClick={() => handleMenuStatus("simulation1")}>
              Simulation 1
            </MenuItem>
            <MenuItem onClick={() => handleMenuStatus("simulation2")}>
              Simulation 2
            </MenuItem>
            {/* <MenuItem onClick={() => handleMenuStatus("result")}>
              생산계획 실적 표
            </MenuItem> */}
          </SubMenu>
          <MenuItem
            onClick={() => handleMenuStatus("lineSummary")}
            icon={<Todo />}
          >
            Line별 Summary
          </MenuItem>
          <MenuItem
            onClick={() => handleMenuStatus("worker")}
            icon={<UserConstructionWorker />}
          >
            인원 현황
          </MenuItem>
          {/* <MenuItem
            onClick={() => handleMenuStatus("simResult")}
            icon={<FolderChartPie />}
          >
            Simulation결과
          </MenuItem> */}
        </Menu>
      </s.TopWrapper>
      <s.BottomWrapper>
        <Menu iconShape="square">
          <MenuItem
            onClick={() => handleKpiCardStatus()}
            icon={<LightbulbIcon />}
          >
            KPI 지표
          </MenuItem>
          <MenuItem
            onClick={() => handleGraphStatus()}
            icon={<AssessmentIcon />}
          >
            그래프
          </MenuItem>
          <MenuItem onClick={() => handleLogout()} icon={<ExitToAppIcon />}>
            로그아웃
          </MenuItem>
        </Menu>
      </s.BottomWrapper>
    </Sidebar>
  );
}

export default SideBar;

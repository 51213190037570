import { useState, useEffect } from "react";
import axios from "axios";
import API_URL from "../utils/API_URL";
import { useSelector, useDispatch } from "react-redux";
import { setPopShowing, setPopStatus } from "../slice/authSlice";
import { createBarChartOptions } from "../utils/chartConfig";
function createCommonSeries(name, data, color, labelPosition = "insideTop") {
  return {
    name,
    type: "bar",
    data,
    color,
    label: {
      show: true,
      position: labelPosition,
      color: "white",
      formatter: function (params) {
        return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      fontSize: 10,
    },
  };
}
function createLineSeries(name, data, color) {
  return {
    name,
    type: "line",
    data,
    label: {
      show: true,
      position: "top",
      color: "black",
      backgroundColor: "white",
      borderColor: color,
      borderWidth: 1,
      borderRadius: 5,
      padding: [1, 1],
      formatter: function (params) {
        return params.value + "%";
      },
      fontSize: 10,
    },
    lineStyle: { color },
    itemStyle: { color },
    yAxisIndex: 2,
  };
}
function createCommonYAxis(name, min, max, interval) {
  return {
    name,
    type: "value",
    show: false,
    min,
    max,
    interval,
  };
}
function createChartOptions(title, xAxisData, series, yAxis, overlayHandler) {
  return createBarChartOptions(
    title,
    xAxisData,
    series,
    yAxis,
    overlayHandler,
    false,
    true
  );
}
export const useWorkerData = () => {
  const dispatch = useDispatch();
  const popStatus = useSelector((state) => state.auth.popStatus);
  const [loading, setLoading] = useState(false);
  const [workerOptions, setWorkerOptions] = useState({});
  const [workerBig, setWorkerBig] = useState({});
  const [workerTitle, setWorkerTitle] = useState([]);
  const handleOverlay = (param) => {
    dispatch(setPopStatus(!popStatus));
    dispatch(setPopShowing(param));
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${API_URL}/data/getDataPlanPerformance`);
      const monthSet = [...new Set(res.data.map((item) => item.pp_month))];
      const newWorkerOptions = {};

      // 월별 공수투입율
      newWorkerOptions.workerOptions1 = createChartOptions(
        "월별 공수투입율",
        monthSet,
        [
          createCommonSeries(
            "가용공수",
            res.data.map((item) => item.pp_manhour_usable),
            "#6A60A9"
          ),
          {
            ...createCommonSeries(
              "부하공수",
              res.data.map((item) => item.pp_manhour_load),
              "#DEDCEE"
            ),
            yAxisIndex: 1,
          },
          createLineSeries(
            "공수투입율",
            res.data.map((item) =>
              ((item.pp_manhour_load / item.pp_manhour_usable) * 100).toFixed(1)
            ),
            "#FC913A"
          ),
        ],
        [
          createCommonYAxis("가용공수", 5000, 15000, 200),
          createCommonYAxis("부하공수", 5000, 15000, 200),
          createCommonYAxis("공수투입율", 60, 105, 1),
        ],
        () => handleOverlay("worker1")
      );

      newWorkerOptions.workerOptions2 = createChartOptions(
        "월별 공수가동율",
        monthSet,
        [
          createCommonSeries(
            "부하공수",
            res.data.map((item) => item.pp_manhour_load),
            "#6A60A9"
          ),
          {
            ...createCommonSeries(
              "작업공수",
              res.data.map((item) => item.pp_manhour_work),
              "#DEDCEE"
            ),
            yAxisIndex: 1,
          },
          createLineSeries(
            "공수가동율",
            res.data.map((item) =>
              ((item.pp_manhour_work / item.pp_manhour_load) * 100).toFixed(1)
            ),
            "#FC913A"
          ),
        ],
        [
          createCommonYAxis("부하공수", 8000, 12000, 1000),
          createCommonYAxis("작업공수", 8000, 12000, 1000),
          createCommonYAxis("공수가동율", 80, 105, 1),
        ],
        () => handleOverlay("worker2")
      );

      newWorkerOptions.workerOptions3 = createChartOptions(
        "월별 공수유실율",
        monthSet,
        [
          createCommonSeries(
            "부하공수",
            res.data.map((item) => item.pp_manhour_load),
            "#6A60A9"
          ),
          {
            ...createCommonSeries(
              "유실공수",
              res.data.map(
                (item) => item.pp_manhour_load - item.pp_manhour_work
              ),
              "#DEDCEE"
            ),
            yAxisIndex: 1,
          },
          createLineSeries(
            "유실율",
            res.data.map((item) =>
              (
                ((item.pp_manhour_load - item.pp_manhour_work) /
                  item.pp_manhour_load) *
                100
              ).toFixed(1)
            ),
            "#FC913A"
          ),
        ],
        [
          createCommonYAxis("부하공수", 8000, 12000, 1000),
          createCommonYAxis("유실공수", 100, 3000, 100),
          createCommonYAxis("유실율", 1, 20, 4),
        ],
        () => handleOverlay("worker3")
      );

      // 노동생산성(UPPH)
      newWorkerOptions.workerOptions4 = createBarChartOptions(
        "노동생산성(UPPH)",
        monthSet,
        [
          {
            data: res.data.map((item) =>
              (item.pp_performance / item.pp_manhour_usable).toFixed(1)
            ),
            type: "line",
            color: "#FC913A",
            label: {
              show: true,
              position: "top",
              color: "black",
              backgroundColor: "white",
              borderColor: "#FC913A",
              borderWidth: 1,
              borderRadius: 35,
              padding: [2, 2],
              fontSize: 10,
            },
            fontSize: 10,
          },
        ],
        [
          {
            type: "value",
            name: "UPPH",
            min: 0,
            axisLabel: { color: "white" },
            show: false,
          },
        ],
        () => handleOverlay("worker4")
      );
      setWorkerOptions(newWorkerOptions);
      const createWorkerBigOptions = (options, handleOverlay) => {
        return Object.entries(options).reduce((acc, [key, value], index) => {
          acc[`workerBig${index + 1}`] = createBarChartOptions(
            value.title.text,
            value.xAxis.data,
            value.series,
            value.yAxis,
            () => handleOverlay(`worker${index + 1}`),
            true,
            true
          );
          return acc;
        }, {});
      };

      const newWorkerBig = createWorkerBigOptions(
        newWorkerOptions,
        handleOverlay
      );
      setWorkerBig(newWorkerBig);
      const totalUpph = String(
        Math.round(
          res.data
            .map((item) =>
              Number((item.pp_performance / item.pp_manhour_usable).toFixed(1))
            )
            .reduce((acc, cur) => acc + cur) / res.data.length
        )
      );
      const total1 = (
        res.data
          .map((item) =>
            Number(
              ((item.pp_manhour_load / item.pp_manhour_usable) * 100).toFixed(1)
            )
          )
          .reduce((acc, cur) => acc + cur) / res.data.length
      ).toFixed(1);
      const total2 = (
        res.data
          .map((item) =>
            Number(
              ((item.pp_manhour_work / item.pp_manhour_load) * 100).toFixed(1)
            )
          )
          .reduce((acc, cur) => acc + cur) / res.data.length
      ).toFixed(1);
      const title = [
        {
          id: 1,
          label: "노동생산성",
          value: totalUpph,
          color: "#c5f6fa",
        },
        {
          id: 2,
          label: "공수투입율",
          value: total1 + "%",
          color: "#B4E5FF",
        },
        {
          id: 3,
          label: "공수가동율",
          value: total2 + "%",
          color: "#80D1FF",
        },
      ];
      setWorkerTitle(title);
    } catch (error) {
      console.error("Error fetching worker data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return {
    loading,
    workerOptions,
    workerBig,
    workerTitle,
  };
};

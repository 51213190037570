import React, { useMemo } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table as BTable } from "react-bootstrap";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from "@tanstack/react-table";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import API_URL from "../../../utils/API_URL";
import axios from "../../../api/axios";
import { resultColumns } from "../../../utils/columns";
import { useDispatch, useSelector } from "react-redux";
import { ThElement } from "../../M/styles";
import { Dim, SummaryOverlay } from "../../Graph/styles";
import { setSummaryResultStatus } from "../../../slice/authSlice";
import styled from "styled-components";

const StyledTable = styled(BTable)`
  .t_thead {
    position: sticky;
    top: 0;
    background-color: #f8f9fa;
    z-index: 1;
  }
`;

const FixedHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ScrollableContent = styled.div`
  max-height: calc(100% - 80px);
  overflow-y: auto;
  padding: 10px;
`;

const Title = styled.div`
  flex-grow: 1;
  text-align: center;
  width: 100%;
`;

function SummaryResult() {
  const resultDataQuery = useQuery({
    queryKey: ["resultDataQuery"],
    queryFn: async () => {
      const res = await axios.get(API_URL + "/data/getPlanResultSummary");
      return res.data;
    },
    placeholderData: keepPreviousData,
  });
  const dispatch = useDispatch();
  const summaryResultStatus = useSelector(
    (state) => state.auth.summaryResultStatus
  );

  const columns = useMemo(() => {
    return [
      ...resultColumns.slice(0, -1),
      {
        ...resultColumns[resultColumns.length - 1],
        cell: ({ row }) => {
          const planQty = parseFloat(row.original.plan_qty) || 0;
          const resultQty = parseFloat(row.original.result_qty) || 0;
          const rate = planQty > 0 ? (resultQty / planQty) * 100 : 0;
          const formattedRate = rate.toFixed(2) + "%";
          const color = rate >= 100 ? "red" : "blue";
          return <span style={{ color }}>{formattedRate}</span>;
        },
      },
    ];
  }, []);

  const table = useReactTable({
    data: resultDataQuery.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        { id: "item_name", desc: false },
        { id: "mch_name", desc: false },
      ],
    },
    debugTable: true,
  });

  const mergedCells = useMemo(() => {
    if (!resultDataQuery.data) return {};

    const mergedInfo = {};
    table.getRowModel().rows.forEach((row, index) => {
      const itemName = row.getValue("item_name");
      const mchName = row.getValue("mch_name");

      if (!mergedInfo[itemName]) {
        mergedInfo[itemName] = { startIndex: index, rowSpan: 1, type: "item" };
      } else {
        mergedInfo[itemName].rowSpan += 1;
      }

      const key = `${itemName}-${mchName}`;
      if (!mergedInfo[key]) {
        mergedInfo[key] = { startIndex: index, rowSpan: 1, type: "mch" };
      } else {
        mergedInfo[key].rowSpan += 1;
      }
    });
    return mergedInfo;
  }, [table, resultDataQuery.data]);

  if (resultDataQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (resultDataQuery.isError) {
    return <div>Error: {resultDataQuery.error.message}</div>;
  }

  return (
    <>
      {summaryResultStatus && (
        <Dim onClick={() => dispatch(setSummaryResultStatus(false))}>
          <SummaryOverlay>
            <FixedHeader>
              <Title className="summary_title">생산계획 실적</Title>
            </FixedHeader>
            <ScrollableContent>
              <StyledTable
                className="t_table"
                striped
                bordered
                hover
                responsive
                size="sm"
              >
                <thead className="t_thead">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr className="t_tr" key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <ThElement
                          className="t_th center"
                          key={header.id}
                          colSpan={header.colSpan}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </ThElement>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className="t_tbody">
                  {table.getRowModel().rows.map((row, rowIndex) => (
                    <tr className="t_tr" key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        if (
                          cell.column.id === "item_name" ||
                          cell.column.id === "mch_name"
                        ) {
                          const value = cell.getValue();
                          const key =
                            cell.column.id === "item_name"
                              ? value
                              : `${row.getValue("item_name")}-${value}`;
                          const mergedInfo = mergedCells[key];
                          if (
                            mergedInfo &&
                            mergedInfo.startIndex === rowIndex
                          ) {
                            return (
                              <td
                                className="t_td"
                                key={cell.id}
                                rowSpan={mergedInfo.rowSpan}
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            );
                          }
                          return null;
                        }
                        return (
                          <td className="t_td" key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </ScrollableContent>
          </SummaryOverlay>
        </Dim>
      )}
    </>
  );
}

export default SummaryResult;

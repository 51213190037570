import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import LoginPage from "../components/Login/LoginPage";
import SfvPage from "../components/SfvPage/SfvPage";
import ThemeComponent from "../themes/ThemeComponent";
import AppLayout from "../layouts/AppLayout"; // 모바일 페이지 레이아웃
import QrPage from "../components/Mobile/QrPage";
import QrScanPage from "../components/Mobile/QrPage/QrScanPage";
import MobileMainPage from "../components/Mobile/MobileMainPage"; 
import ChecklistPage from "../components/Mobile/ChecklistPage";

function AllRoute() {
  const darkSettings = {
    mode: "dark",
  };
  const lightSettings = {
    mode: "light",
  };

  return (
    <Routes>
      {/* 기본 페이지 라우팅 */}
      <Route
        path="/"
        element={
          <ThemeComponent settings={darkSettings}>
            <LoginPage />
          </ThemeComponent>
        }
      />

      {/* 보호된 라우트 */}
      <Route
        path="/sfv"
        element={
          <ThemeComponent settings={darkSettings}>
            <ProtectedRoute children={<SfvPage />} />
          </ThemeComponent>
        }
      />

      {/* /mobile의 하위 페이지들을 위한 공통 레이아웃 */}
      <Route
        path="/mobile"
        element={
          <ThemeComponent settings={lightSettings}>
            <ProtectedRoute children={<AppLayout />} /> {/* 공통 레이아웃 적용 */}
          </ThemeComponent>
        }
      >
        {/* /mobile 기본 페이지 */}
        <Route index element={<MobileMainPage />} />
        <Route path="qr" element={<QrPage />} />
        <Route path="qrscan" element={<QrScanPage />} />
        <Route path="checklist" element={<ChecklistPage />} />
      </Route>
    </Routes>
  );
}

export default AllRoute;
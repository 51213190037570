import React, { useMemo } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table as BTable } from "react-bootstrap";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import API_URL from "../../../utils/API_URL";
import axios from "../../../api/axios";
import { useDispatch, useSelector } from "react-redux";
import { ThElement } from "../../M/styles";
import { SummaryCloseBtn, Dim, SummaryOverlay } from "../../Graph/styles";
import { setPlanWeek } from "../../../slice/authSlice";
import styled from "styled-components";

const StyledTable = styled(BTable)`
  th,
  td {
    border: 1px solid #d3d3d3 !important;
  }
  .t_thead {
    position: sticky;
    top: 0;
    background-color: #f8f9fa;
    z-index: 1;
    border: none !important;
  }
  table-layout: fixed;
  border: none !important;
  width: 100%;
  .t_tr .t_th:first-child {
    border-left: 1px solid #d3d3d3 !important;
  }
  .t_tr.sub-header .t_th {
    border-bottom: 1px solid #d3d3d3 !important;
  }
  .t_head .t_tr th {
    border: 1px solid #d3d3d3 !important;
  }
  th:first-child,
  td:first-child {
    width: 100px;
  }
  th[colspan="2"] {
    width: 140px;
  }
  .t_thead .t_tr:nth-child(2) .t_th:first-child {
    border-bottom: 1px solid #d3d3d3 !important;
  }
  th:not(:first-child):not([colspan]),
  td:not(:first-child) {
    width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const FixedHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ScrollableContent = styled.div`
  max-height: calc(100% - 80px);
  overflow-y: auto;
  padding: 10px;
`;

const Title = styled.div`
  flex-grow: 1;
  text-align: center;
  width: 100%;
`;

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const weekdays = ["일", "월", "화", "수", "목", "금", "토"];
  const weekday = weekdays[date.getDay()];
  return `${month}월 ${day}일(${weekday})`;
};

function PlanWeek() {
  const planWeekQuery = useQuery({
    queryKey: ["planQuery"],
    queryFn: async () => {
      const res = await axios.get(API_URL + "/data/getDataPlanWeek");
      const convertDateFormatData = res.data.map((item) => {
        return { ...item, due_date: item.due_date.slice(0, 10) };
      });
      return convertDateFormatData;
    },
    placeholderData: keepPreviousData,
  });
  const dispatch = useDispatch();
  const planWeek = useSelector((state) => state.auth.planWeek);

  const { columns, data } = useMemo(() => {
    if (!planWeekQuery.data) return { columns: [], data: [] };

    const getWeekDates = (startDate) => {
      const start = new Date(startDate);
      const day = start.getDay();
      const diff = start.getDate() - day + (day === 0 ? -6 : 1); // Adjust when day is sunday
      const monday = new Date(start.setDate(diff));

      return Array.from({ length: 7 }, (_, i) => {
        const date = new Date(monday);
        date.setDate(monday.getDate() + i);
        return date.toISOString().split("T")[0];
      });
    };

    const sortedDates = [
      ...new Set(planWeekQuery.data.map((item) => item.due_date)),
    ].sort();
    const weekDates = getWeekDates(sortedDates[0]);
    const uniqueItems = [
      ...new Set(planWeekQuery.data.map((item) => item.item_name)),
    ];

    const columns = [
      { header: "", accessorKey: "item_name" },
      ...weekDates.map((date) => ({
        header: formatDate(date),
        columns: [
          { header: "계획수량", accessorKey: `order_${date}` },
          { header: "생산수량", accessorKey: `result_${date}` },
        ],
      })),
    ];

    const data = uniqueItems.map((item) => {
      const rowData = { item_name: item };
      weekDates.forEach((date) => {
        const matchingItem = planWeekQuery.data.find(
          (d) => d.item_name === item && d.due_date === date
        );
        rowData[`order_${date}`] = matchingItem ? matchingItem.order_total : 0;
        rowData[`result_${date}`] = matchingItem
          ? matchingItem.result_total
          : 0;
      });
      return rowData;
    });

    return { columns, data };
  }, [planWeekQuery.data]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (planWeekQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (planWeekQuery.isError) {
    return <div>Error: {planWeekQuery.error.message}</div>;
  }

  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {planWeek && (
        <Dim onClick={() => dispatch(setPlanWeek(false))}>
          <SummaryOverlay onClick={handleOverlayClick}>
            <FixedHeader>
              <Title className="summary_title">주간생산계획</Title>
              <SummaryCloseBtn onClick={() => dispatch(setPlanWeek(false))} />
            </FixedHeader>
            <hr className="divider" />
            <ScrollableContent>
              <StyledTable
                className="t_table"
                striped
                bordered
                hover
                responsive
                size="sm"
              >
                <thead className="t_thead">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr className="t_tr" key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <ThElement
                          className="t_th center"
                          key={header.id}
                          colSpan={header.colSpan}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </ThElement>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className="t_tbody">
                  {table.getRowModel().rows.map((row) => (
                    <tr className="t_tr" key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td
                          className="t_td"
                          key={cell.id}
                          style={{
                            verticalAlign: "middle",
                            textAlign: "center",
                            height: "40px",
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </ScrollableContent>
          </SummaryOverlay>
        </Dim>
      )}
    </>
  );
}

export default PlanWeek;

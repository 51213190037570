import axios from "axios";
import API_URL from "../utils/API_URL";
import { useEffect, useState } from "react";

export const useDataLineWorkerData = () => {
  const [lineWorkerData, setLineWorkerData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get(API_URL + "/data/getDataLineWorker");
        setLineWorkerData(res.data);
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, []);
  return {
    lineWorkerData,
  };
};

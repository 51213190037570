import React from 'react';

function AutomatedLogistics(props) {
	return (
		<svg height="22" width="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
	<g fill="#A0AEC0">
		<path d="M21.71 6.62l-3.31-2.48v-4.14h-1.84v4.14l-3.31 2.48a0.92 0.92 0 0 0-0.37 0.74v2.76a0.92 0.92 0 0 0 0.92 0.92 0.92 0.92 0 0 0 0.92-0.92v-2.3l2.76-2.07 2.76 2.07v2.3a0.92 0.92 0 0 0 0.92 0.92 0.92 0.92 0 0 0 0.92-0.92v-2.76a0.92 0.92 0 0 0-0.37-0.74z" fill="#A0AEC0"/>
		<path d="M13.8 12.88h8.28v5.52h-9.2v-5.52h0.92z" fill="#A0AEC0"/>
		<path d="M0.92 20.24h21.16v1.84h-22.08v-1.84h0.92z" fill="#A0AEC0"/>
		<path d="M10.12 7.36h-9.2a0.92 0.92 0 0 0-0.92 0.92v9.2a0.92 0.92 0 0 0 0.92 0.92h9.2a0.92 0.92 0 0 0 0.92-0.92v-9.2a0.92 0.92 0 0 0-0.92-0.92z" fill="#A0AEC0"/>
	</g>
</svg>
	);
};

export default AutomatedLogistics;
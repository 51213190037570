import styled from "styled-components";

export const CarouselContainer = styled.div`
  position: fixed;
  bottom: 10px;
  left: 230px;
  width: 90%;
`;

export const CardsContainer = styled.div`
  overflow: hidden;
  // width: ${(props) => (props.$isUnityChattingOpend ? "71%" : "92%")};
  pointer-events: auto;
  width: 92%;
  //  width: 92%;
  //95%
`;

export const Cards = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  pointer-events: auto;
`;

export const Card = styled.div`
  flex: ${(props) =>
    props.$isUnityChattingOpend ? "0 0 49.3%" : "0 0 32.76%"};
  padding: 20px;
  background-color: rgba(52, 58, 64, 0.5);
  border: 1.5px solid rgb(8, 13, 48);
  border-radius: 3px;
  margin-right: 10px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
  &:hover {
    // scale: 1.03;
    box-sizing: border-box;
    border: 2.5px solid #22a30f;
    transition: transform 0.5s ease-in-out;
  }
  @media (min-width: 3000px) {
    height: 270px;
  }
`;

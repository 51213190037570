import { createContext, useEffect, useCallback, useState } from "react";
import { isMobile } from 'react-device-detect'; // 모바일 디바이스 감지
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setUser, logout } from "../slice/authSlice";
import { ToastContainer, toast } from "react-toastify";
import authConfig from "../auth/auth";
import { getToken, setToken, clearToken } from "../auth/tokenStore";
import useRefreshToken from "../hooks/useRefreshToken";

const AuthContext = createContext({
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const refresh = useRefreshToken();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log("유저확인:", user);
  }, [user]);

  const initializeAuth = useCallback(async () => {
    const currentToken = getToken();
    if (!currentToken) {
      try {
        await refresh();
      } catch (error) {
        clearToken();
        dispatch(logout());
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [dispatch, refresh]);

  useEffect(() => {
    initializeAuth();
  }, [initializeAuth]);

  const handleLogin = useCallback(
    async (params) => {
      try {
        const response = await axios.post(authConfig.loginEndpoint, params, {
          withCredentials: true,
        });
        setToken(response.data.accessToken);
        dispatch(setUser(response.data.user));

        // 로그인 후 모바일과 데스크탑에 따라 다른 경로로 리다이렉트
        if (isMobile) {
          navigate("/mobile"); // 모바일이면 /mobile로 이동
        } else {
          navigate("/sfv"); // 데스크탑이면 /sfv로 이동
        }

      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error(error.response.data);
        } else {
          toast.error(error.response.data);
        }
      }
    },
    [navigate, dispatch]
  );

  const handleLogout = useCallback(async () => {
    try {
      await axios.post(
        authConfig.logoutEndpoint,
        {},
        { withCredentials: true }
      );
      clearToken();
      dispatch(logout());
      navigate("/");
    } catch (error) {
      toast.error("로그아웃에 문제가 발생했습니다.");
    }
  }, [navigate, dispatch]);

  const values = {
    user,
    setUser,
    login: handleLogin,
    logout: handleLogout,
  };

  return (
    <AuthContext.Provider value={values}>
      {isLoading ? <p>Loading...</p> : children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ChartBox,
  Dim,
  GanttOverlay,
  SummaryCloseBtn,
} from "../../Graph/styles";
import { Gantt, gantt } from "dhtmlx-gantt";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import "dhtmlx-gantt/codebase/skins/dhtmlxgantt_meadow.css";
import { setSimulation1 } from "../../../slice/authSlice";
import styled from "styled-components";
import { useGanttSimulation1 } from "../../../hooks/useGanttSimulation1";

const FixedHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.div`
  flex-grow: 1;
  text-align: center;
  width: 100%;
`;

let simGantt1;

function GanttSimulation1() {
  let simGanttRef1 = useRef();
  const { simGData } = useGanttSimulation1();
  const simulation1 = useSelector((state) => state.auth.simulation1);
  const dispatch = useDispatch();
  useEffect(() => {
    simGantt1 = Gantt.getGanttInstance();
    simGantt1.plugins({
      tooltip: true,
    });
    simGantt1.config.sort = true;
    simGantt1.config.drag_move = false;
    simGantt1.config.duration_unit = "minute";
    simGantt1.config.scales = [
      {
        unit: "hour",
        format: function (date) {
          return date.getHours() + "시";
        },
        step: "1",
        scale_height: 50,
        min_column_width: 45,
      },
    ];
    simGantt1.config.columns = [
      {
        name: "text",
        label: "장비명",
        width: 120,
        align: "center",
        resize: false,
      },
    ];
    simGantt1.attachEvent("onTaskDblClick", function (id, e) {
      return false;
    });
    simGantt1.config.start_date = new Date(2024, 9, 22, 8);
    simGantt1.config.end_date = new Date(2024, 9, 22, 23);
    simGantt1.templates.tooltip_text = function (start, end, task) {
      return (
        "<b>· 아이템 : " +
        task.item_name +
        " </b>" +
        "<br /><b>· 주문 : " +
        task.order_name +
        " </b>" +
        "<br /><b>· 공정 : " +
        task.proc_name +
        "</b>" +
        "<br /><b>· 수량 : " +
        task.item_qty +
        "개" +
        "</b>" +
        "</br><b>· 시작 :  <b/>" +
        start.getHours() +
        "시 " +
        start.getMinutes() +
        "분" +
        "</br><b>· 종료 :  <b/>" +
        end.getHours() +
        "시 " +
        end.getMinutes() +
        "분"
      );
    };
    simGantt1.config.row_height = 25;
    simGantt1.config.scale_height = 25;
    simGantt1.init(simGanttRef1.current);

    simGantt1.parse({
      data: simGData,
    });
  }, [simulation1, simGData]);
  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };
  return (
    <>
      <Dim
        onClick={() => dispatch(setSimulation1(false))}
        style={{ display: simulation1 ? "block" : "none" }}
      >
        <GanttOverlay
          //    onClick={handleOverlayClick}
          style={{ zIndex: 0 }}
          onClick={handleOverlayClick}
        >
          <FixedHeader>
            <Title className="summary_title">Simulation1</Title>
            <SummaryCloseBtn onClick={() => dispatch(setSimulation1(false))} />
          </FixedHeader>
          <hr className="divider" />
          <div
            style={{
              position: "absolute",
              top: "55%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <ChartBox onClick={onCloseDim}> */}
            <div
              ref={simGanttRef1}
              style={{ width: "100%", height: "420px" }}
            />
            {/* </ChartBox> */}
          </div>
        </GanttOverlay>
      </Dim>
    </>
  );
}

export default GanttSimulation1;

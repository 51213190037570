import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import API_URL from "../../utils/API_URL";
import { useDispatch, useSelector } from "react-redux";
import { Dim, SummaryOverlay, SummaryCloseBtn } from "../Graph/styles";
import { setWorkerStatus } from "../../slice/authSlice";
import styled from "styled-components";

const Title = styled.div`
  flex-grow: 1;
  text-align: center;
  width: 100%;
`;
const tableStyle = {
  borderCollapse: "collapse",
  width: "100%",
  backgroundColor: "white",
  color: "black",
  textAlign: "center",
};

const cellStyle = {
  border: "1px solid black",
  padding: "2px 30px",
  height: "26px",
};

const cellStyle2 = {
  border: "1px solid black",
  padding: "2px 50px",
  height: "26px",
};

const totalCellStyle = {
  border: "1px solid black",
  padding: "2px 5px",
  height: "26px",
  fontWeight: "500",
};

const FixedHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

function WorkerTable() {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const workerStatus = useSelector((state) => state.auth.workerStatus);
  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL + "/data/getDataLineWorker");
        setData(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const processedData = useMemo(() => {
    const groupedData = data.reduce((acc, item) => {
      if (!acc[item.line_name]) {
        acc[item.line_name] = [];
      }
      acc[item.line_name].push(item);
      return acc;
    }, {});

    return Object.entries(groupedData).map(([lineName, items]) => ({
      name: lineName,
      items: items,
      subtotal: {
        registered: items.reduce((sum, item) => sum + item.registered, 0),
        attended: items.reduce((sum, item) => sum + item.attended, 0),
        dayoff: items.reduce((sum, item) => sum + item.dayoff, 0),
        absence: items.reduce((sum, item) => sum + item.absence, 0),
        overtime: items.reduce((sum, item) => sum + item.overtime, 0),
      },
    }));
  }, [data]);

  const total = useMemo(
    () => ({
      registered: processedData.reduce(
        (sum, group) => sum + group.subtotal.registered,
        0
      ),
      attended: processedData.reduce(
        (sum, group) => sum + group.subtotal.attended,
        0
      ),
      dayoff: processedData.reduce(
        (sum, group) => sum + group.subtotal.dayoff,
        0
      ),
      absence: processedData.reduce(
        (sum, group) => sum + group.subtotal.absence,
        0
      ),
      overtime: processedData.reduce(
        (sum, group) => sum + group.subtotal.overtime,
        0
      ),
    }),
    [processedData]
  );

  if (data.length === 0) {
    return <div>데이터를 불러오는 중...</div>;
  }

  return (
    <>
      {workerStatus && (
        <Dim onClick={() => dispatch(setWorkerStatus(false))}>
          <SummaryOverlay onClick={handleOverlayClick}>
            <FixedHeader>
              <Title className="summary_title">인원현황</Title>
              <SummaryCloseBtn
                onClick={() => dispatch(setWorkerStatus(false))}
              />
            </FixedHeader>
            <hr className="divider" />
            <table style={tableStyle}>
              <thead>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <th style={cellStyle} rowSpan="2">
                    작업장명
                  </th>
                  <th style={cellStyle} colSpan="2">
                    설비
                  </th>
                  <th style={cellStyle2} colSpan="5">
                    인원
                  </th>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <th style={cellStyle}>설비명</th>
                  <th style={cellStyle}>설비대수</th>
                  <th style={cellStyle}>재적</th>
                  <th style={cellStyle}>현재원</th>
                  <th style={cellStyle}>휴가</th>
                  <th style={cellStyle}>결근</th>
                  <th style={cellStyle}>잔업</th>
                </tr>
              </thead>
              <tbody>
                {processedData.map((group, index) => (
                  <React.Fragment key={index}>
                    {group.items.map((item, itemIndex) => (
                      <tr key={`${index}-${itemIndex}`}>
                        {itemIndex === 0 && (
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                            rowSpan={group.items.length + 1}
                          >
                            {group.name}
                          </td>
                        )}
                        <td style={cellStyle}>{item.mch_name}</td>
                        <td style={cellStyle}>{item.mch_num}</td>
                        <td style={cellStyle}>{item.registered}</td>
                        <td style={cellStyle}>{item.attended}</td>
                        <td style={cellStyle}>{item.dayoff}</td>
                        <td style={cellStyle}>{item.absence}</td>
                        <td style={cellStyle}>{item.overtime}</td>
                      </tr>
                    ))}
                    <tr style={{ backgroundColor: "#fff9c4" }}>
                      <td style={totalCellStyle} colSpan="2">
                        소계
                      </td>
                      <td style={totalCellStyle}>
                        {group.subtotal.registered}
                      </td>
                      <td style={totalCellStyle}>{group.subtotal.attended}</td>
                      <td style={totalCellStyle}>{group.subtotal.dayoff}</td>
                      <td style={totalCellStyle}>{group.subtotal.absence}</td>
                      <td style={totalCellStyle}>{group.subtotal.overtime}</td>
                    </tr>
                  </React.Fragment>
                ))}
                <tr style={{ backgroundColor: "#ffd54f" }}>
                  <td style={cellStyle} colSpan="3">
                    총계
                  </td>
                  <td style={totalCellStyle}>{total.registered}</td>
                  <td style={totalCellStyle}>{total.attended}</td>
                  <td style={totalCellStyle}>{total.dayoff}</td>
                  <td style={totalCellStyle}>{total.absence}</td>
                  <td style={totalCellStyle}>{total.overtime}</td>
                </tr>
              </tbody>
            </table>
          </SummaryOverlay>
        </Dim>
      )}
    </>
  );
}

export default WorkerTable;

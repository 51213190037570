import React from 'react';

function UserConstructionWorker(props) {
	return (
		<svg height="22" width="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
	<g fill="#a0aec0" strokeLinecap="butt" strokeLinejoin="miter">
		<path d="M17.07 8.28c0.03 0.3 0.03 0.6 0.01 0.91l-0.18 2.78a5.88 5.88 0 0 1-5.86 5.51 5.88 5.88 0 0 1-5.86-5.51l-0.18-2.78a6.08 6.08 0 0 1 0.01-0.91" fill="none" stroke="#a0aec0" strokeMiterlimit="10" strokeWidth="2"/>
		<path d="M3.68 20.63l3.27-0.93a1.84 1.84 0 0 0 1.33-1.77v-1.14" fill="none" stroke="#a0aec0" strokeMiterlimit="10" strokeWidth="2"/>
		<path d="M18.4 20.63l-3.27-0.93a1.84 1.84 0 0 1-1.33-1.77v-1.14" fill="none" stroke="#a0aec0" strokeMiterlimit="10" strokeWidth="2"/>
		<path d="M18.86 8.28h-15.64" fill="none" stroke="#a0aec0" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2"/>
		<path d="M4.6 8.28v-0.92a6.44 6.44 0 1 1 12.88 0v0.92" fill="none" stroke="#a0aec0" strokeMiterlimit="10" strokeWidth="2"/>
		<path d="M11.04 3.68v-2.76 0.92" fill="none" stroke="#a0aec0" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2"/>
		<path d="M11.04 6.44a0.92 0.92 0 1 0 0 1.84 0.92 0.92 0 1 0 0-1.84z" fill="#a0aec0" stroke="#a0aec0" strokeMiterlimit="10" strokeWidth="2"/>
	</g>
</svg>
	);
};

export default UserConstructionWorker;
import React, { useState } from "react";
import { Dim, SummaryOverlay } from "../../Graph/styles";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "styled-components";
import { setLineSummaryStatus } from "../../../slice/authSlice";
import PictInPictTopRight from "../../NucleoIcons/icons/pict-in-pict-top-right";

const category = [
  {
    id: 1,
    name: "메인 조립 라인",
    cameraNumber: 40,
    floor: "100000",
    group: "111111",
  },
  {
    id: 2,
    name: "단조 라인",
    cameraNumber: 37,
    floor: "100000",
    group: "111111",
  },
  {
    id: 3,
    name: "사출 라인",
    cameraNumber: 30,
    floor: "100000",
    group: "111111",
  },
  {
    id: 4,
    name: "CNC 가공 라인",
    cameraNumber: 32,
    floor: "100000",
    group: "111111",
  },
  {
    id: 5,
    name: "프레스 라인",
    cameraNumber: 29,
    floor: "100000",
    group: "111111",
  },
  {
    id: 6,
    name: "SMT 라인",
    cameraNumber: 44,
    floor: "010000",
    group: "111111",
  },
  {
    id: 7,
    name: "검사라인",
    cameraNumber: 48,
    floor: "010000",
    group: "111111",
  },
];

const lineData = [
  {
    id: 1,
    data: [
      ["생산달성율", "월누적달성율", "67.4%"],
      ["생산달성율", "일일 달성율", "51.8%"],
      ["일일 생산수량", "조립1라인", "172"],
      ["일일 생산수량", "조립2라인", "141"],
      ["일일 UPH", "조립1라인", "31"],
      ["일일 UPH", "조립2라인", "26"],
    ],
  },
  {
    id: 2,
    data: [
      ["생산달성율", "월누적달성율", "72.3%"],
      ["생산달성율", "일일 달성율", "60.3%"],
      ["일일 가동율", "단조", "82.2%"],
      ["일일 가동율", "태핑", "80.1%"],
      ["일일 UPH", "단조", "155"],
      ["일일 UPH", "태핑", "2400"],
    ],
  },
  {
    id: 3,
    data: [
      ["생산달성율", "월누적달성율", "66.5%"],
      ["생산달성율", "일일 달성율", "50.2%"],
      ["일일 가동율", "1100톤", "88.2%"],
      ["일일 가동율", "180톤", "81.5%"],
      ["일일 UPH", "1100톤", "41"],
      ["일일 UPH", "180톤", "83"],
    ],
  },
  {
    id: 4,
    data: [
      ["일일 가동율", "TRAY", "80.8%"],
      ["일일 가동율", "CASE", "70.3%"],
      ["일일 가동율", "MID", "78.9%"],
      ["일일 UPH", "TRAY", "25"],
      ["일일 UPH", "CASE", "22"],
      ["일일 UPH", "MID", "62"],
    ],
  },
  {
    id: 5,
    data: [
      ["생산달성율", "월누적달성율", "64.5%"],
      ["생산달성율", "일일 달성율", "58.2%"],
      ["일일 가동율", "1500톤", "78.2%"],
      ["일일 가동율", "600톤", "75.8%"],
      ["일일 UPH", "1500톤", "850"],
      ["일일 UPH", "600톤", "993"],
    ],
  },
  {
    id: 6,
    data: [
      ["생산달성율", "월누적달성율", "72.5%"],
      ["생산달성율", "일일 달성율", "64.2%"],
      ["일일 생산수량", "SMT1라인", "1032"],
      ["일일 생산수량", "SMT2라인", "845"],
      ["일일 UPH", "SMT1라인", "172"],
      ["일일 UPH", "SMT2라인", "141"],
    ],
  },
  {
    id: 7,
    data: [
      ["검사수량", "월누적 검사수량", "44802"],
      ["검사수량", "검사대기수량", "3885"],
      ["검사 완료율", "91%"],
    ],
  },
];
const Button = styled.button`
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${(props) => (props.active ? "#007bff" : "#f0f0f0")};
  color: ${(props) => (props.active ? "white" : "black")};
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-top: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  min-width: 300px;
  font-size: 1.09rem;
`;

const GradientTd = styled(Td)`
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #afedcf;
    width: ${(props) => props.percentage};
    z-index: 1;
  }

  > span {
    position: relative;
    z-index: 2;
  }
`;

function SummaryLine({ sendMessage }) {
  const [activeTab, setActiveTab] = useState(1);
  const lineSummaryStatus = useSelector(
    (state) => state.auth.lineSummaryStatus
  );
  const dispatch = useDispatch();
  const activeObj = category.find((i) => i.id === activeTab)?.name;

  const renderTable = () => {
    const activeData = lineData.find((item) => item.id === activeTab);
    if (!activeData) return null;

    if (activeTab === 7) {
      return (
        <Table>
          <tbody>
            <tr>
              <Td rowSpan={2}>검사수량</Td>
              <Td>월누적 검사수량</Td>
              <Td>{activeData.data[0][2]}</Td>
            </tr>
            <tr>
              <Td>검사 대기수량</Td>
              <Td>{activeData.data[1][2]}</Td>
            </tr>
            <tr>
              <Td colSpan={2}>검사 완료율</Td>
              <GradientTd percentage={activeData.data[2][1]}>
                <span>{activeData.data[2][1]}</span>
              </GradientTd>
            </tr>
          </tbody>
        </Table>
      );
    }

    const groupedData = {};
    activeData.data.forEach(([group, subgroup, value]) => {
      if (!groupedData[group]) {
        groupedData[group] = [];
      }
      groupedData[group].push([subgroup, value]);
    });

    return (
      <Table>
        <tbody>
          {Object.entries(groupedData).map(([group, items], index) => (
            <React.Fragment key={index}>
              <tr>
                <Td rowSpan={items.length + 1}>{group}</Td>
              </tr>
              {items.map(([subgroup, value], subIndex) => (
                <tr key={`${index}-${subIndex}`}>
                  <Td>{subgroup}</Td>
                  {typeof value === "string" && value.includes("%") ? (
                    <GradientTd percentage={value}>
                      <span>{value}</span>
                    </GradientTd>
                  ) : (
                    <Td>{value}</Td>
                  )}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    );
  };
  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };
  const onUnityCameraView = () => {
    const cameraObj = category.find((i) => i.id === activeTab);
    dispatch(setLineSummaryStatus(false));
    sendMessage(
      "Canvas",
      "SetCameraNumberView",
      String(cameraObj.cameraNumber)
    );
    sendMessage("Canvas", "SetFloorVisible", String(cameraObj.floor));
    sendMessage("Canvas", "SetGroupVisible", String(cameraObj.group));
  };
  return (
    <>
      {lineSummaryStatus && (
        <Dim onClick={() => dispatch(setLineSummaryStatus(false))}>
          <SummaryOverlay $isSummayLine onClick={handleOverlayClick}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              {category.map((content) => (
                <Button
                  key={content.id}
                  active={activeTab === content.id}
                  onClick={() => setActiveTab(content.id)}
                >
                  {content.name}
                </Button>
              ))}
            </div>
            <div className="summary_title week_box_flex">
              {activeObj}
              <div onClick={onUnityCameraView} style={{ cursor: "pointer" }}>
                <PictInPictTopRight />
              </div>
            </div>
            {renderTable()}
          </SummaryOverlay>
        </Dim>
      )}
    </>
  );
}

export default SummaryLine;

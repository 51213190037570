import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card, Grid, Typography, CardContent } from '@mui/material';
import CustomAvatar from "../../mui/avartar";
import { Icon } from '../../icons';

const MobileMainPage = () => {
  const navigate = useNavigate();
  
  const user = useSelector((state) => state.auth.user);
  const selectedAssetCode = useSelector((state) => state.auth.qrData);

  const handleCardClick = (path) => {
    navigate(`/mobile/${path}`);
  };

  return (
    <Grid container spacing={0} sx={{ overflowY: 'auto', p: 4 }}>
      <Grid item xs={12}>
        {/* QR 코드 장비 선택 카드 */}
        <Card sx={{ mb: '10px', cursor: 'pointer' }} onClick={() => handleCardClick('qr')}>
          <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
            <CustomAvatar
              skin="light"
              color="primary"
              sx={{ width: 40, height: 40, mb: 2 }}
              variant="rounded"
            >
              <Icon 
                name="qrIcon"
                style={{ 
                  width: "24px", 
                  height: "24px",
                }}
              />
            </CustomAvatar>
            <Typography variant='h5'>장비 선택</Typography>
            {/* 선택된 장비 정보 표시 */}
            <Typography variant='body2' color='text.secondary'>
              선택된 장비: {selectedAssetCode ? selectedAssetCode : '선택된 장비 없음'}
            </Typography>
          </CardContent>
        </Card>

        {/* 작업안전수칙 카드 */}
        <Card sx={{ mb: '10px', cursor: 'pointer' }} onClick={() => handleCardClick('checklist')}>
          <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
            <CustomAvatar
              skin="light"
              color="primary"
              sx={{ width: 40, height: 40, mb: 2 }}
              variant="rounded"
            >
              <Icon 
                name="checklistIcon"
                style={{ 
                  width: "24px", 
                  height: "24px",
                }}
              />
            </CustomAvatar>
            <Typography variant='h5'>작업안전수칙</Typography>
          </CardContent>
        </Card>

        {/* 설비 보전 카드 */}
        <Card sx={{ mb: '10px', cursor: 'pointer' }} onClick={() => handleCardClick('repair')}>
          <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
            <CustomAvatar
              skin="light"
              color="primary"
              sx={{ width: 40, height: 40, mb: 2 }}
              variant="rounded"
            >
              <Icon 
                name="repairIcon"
                style={{ 
                  width: "24px", 
                  height: "24px",
                }}
              />
            </CustomAvatar>
            <Typography variant='h5'>설비 보전</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default MobileMainPage;
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const moveUp = keyframes`
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const moveDown = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100px);
    opacity: 0;
  }
`;

const Carousel = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  animation: ${(props) => (props.$carouselVisible ? fadeIn : fadeOut)} 0.5s
    ease-in-out;
`;

const fadeOutCarousel = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100px);
  }
`;
const dropDownCarousel = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const StyledCarousel = styled(Carousel)`
  display: flex;
  align-items: center;
  position: relative;
  pointer-events: none;
  animation: ${(props) =>
      props.$animating
        ? props.$carouselVisible
          ? dropDownCarousel
          : fadeOutCarousel
        : props.$carouselVisible
        ? fadeIn
        : fadeOut}
    0.5s ease-in-out;
`;

export const CarouselContainer = styled.div`
  position: fixed;
  bottom: 10px;
  left: 100px;
  width: ${(props) => (props.$isUnityChattingOpend ? "72%" : "97%")};
  // width: 80%;
  // width: 97%;
  pointer-events: auto;
`;

export const PrevBtn = styled.button`
  background-color: transparent;
  border: none;
  font-size: 5rem;
  color: gray;
  cursor: pointer;
  margin-right: 10px;
  &:hover {
    color: white;
  }
  pointer-events: auto;
`;

export const NextBtn = styled.button`
  background-color: transparent;
  border: none;
  font-size: 5rem;
  color: gray;
  cursor: pointer;
  margin-left: 10px;
  &:hover {
    color: white;
  }
  pointer-events: auto;
`;

export const RedBox = styled.div`
  position: fixed;
  bottom: 0px;
  left: 80px;
  width: ${(props) => !props.$isUnityChattingOpend && "calc(100vw - 80px)"};
  background-color: black;
  overflwo: hidden;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  animation: ${(props) => (props.$carouselVisible ? fadeOut : fadeIn)} 0.5s
    ease-in-out;
  @media (min-width: 3000px) {
    height: 26vh;
  }
`;

export const SelectedCard = styled.div`
  width: 450px;
  height: 100px !important;
  background-color: #343a40;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  cursor: pointer;
  animation: ${(props) => (props.$carouselVisible ? moveDown : moveUp)} 0.5s
    ease-in-out;
  &:hover {
    scale: 1.03;
    transition: transform 0.5s ease-in-out;
  }
  pointer-events: auto;
  @media (min-width: 3000px) {
    top: -40vh;
    width: 1000px;
    height: 250px !important;
  }
`;

export const PrevBtnOutside = styled.button`
  background-color: transparent;
  border: none;
  font-size: 3rem;
  color: gray;
  cursor: pointer;
  font-weight: bold;
  margin-right: 10px;
  &:hover {
    color: white;
  }
`;

export const NextBtnOutside = styled.button`
  background-color: transparent;
  border: none;
  font-size: 3rem;
  color: gray;
  cursor: pointer;
  font-weight: bold;
  margin-left: 10px;
  &:hover {
    color: white;
  }
`;

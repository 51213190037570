import React, { useMemo } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table as BTable } from "react-bootstrap";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from "@tanstack/react-table";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import API_URL from "../../../utils/API_URL";
import axios from "../../../api/axios";
import { itemColumns } from "../../../utils/columns";
import styled from "styled-components";

const StyledTable = styled(BTable)`
  .t_thead {
    position: sticky;
    top: 0;
    background-color: #f8f9fa;
    z-index: 1;
    border: none !important;
  }
  .t_head .t_tr th {
    border: 1px solid #d3d3d3 !important;
  }
  border: none !important;
  .t_tr .t_th:first-child {
    border-left: 1px solid #d3d3d3 !important;
  }
  .tr_ .t_th {
    border-bottom: 1px solid #d3d3d3 !important;
  }
`;

function sortByEngNameFirst(arr) {
  return arr.sort((a, b) => {
    const engRegex = /^[A-Za-z]/;
    if (
      (engRegex.test(a) && engRegex.test(b)) ||
      (!engRegex.test(a) && !engRegex.test(b))
    ) {
      return a.localeCompare(b, "ko-KR");
    }
    if (engRegex.test(a)) {
      return -1;
    }
    if (engRegex.test(b)) {
      return 1;
    }
  });
}

function SummaryItem() {
  const dataQuery = useQuery({
    queryKey: ["getGanttDataTemporary"],
    queryFn: async () => {
      const res = await axios.get(API_URL + "/data/getGanttDataTemporary");
      return res.data;
    },
    placeholderData: keepPreviousData,
  });

  const processedData = useMemo(() => {
    if (!dataQuery.data) return [];
    const groupedByMachine = dataQuery.data.reduce((acc, item) => {
      if (!acc[item.mch_name]) {
        acc[item.mch_name] = [];
      }
      acc[item.mch_name].push(item);
      return acc;
    }, {});
    const result = Object.entries(groupedByMachine).map(([mch_name, items]) => {
      const groupedByItem = items.reduce((acc, item) => {
        if (!acc[item.item_name]) {
          acc[item.item_name] = {
            ...item,
            item_qty: 0,
            result_qty: 0,
            start_date: new Date(item.start_date),
          };
        }
        acc[item.item_name].item_qty += parseInt(item.item_qty, 10) || 1;
        acc[item.item_name].result_qty += parseInt(item.result_qty, 10) || 0;
        acc[item.item_name].start_date = new Date(
          Math.min(acc[item.item_name].start_date, new Date(item.start_date))
        );
        return acc;
      }, {});

      return {
        mch_name,
        items: Object.values(groupedByItem),
      };
    });
    return sortByEngNameFirst(result.map((group) => group.mch_name)).map(
      (mch_name) => result.find((group) => group.mch_name === mch_name)
    );
  }, [dataQuery.data]);

  const columns = useMemo(() => {
    return itemColumns.map((column) => {
      if (column.id === "rate") {
        return {
          ...column,
          cell: ({ row }) => {
            const rate =
              row.original.item_qty !== 0
                ? (row.original.result_qty / row.original.item_qty) * 100
                : 0;
            const formattedRate = rate.toFixed(1) + "%";
            return (
              <div
                style={{ position: "relative", width: "100%", height: "20px" }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#ff8787",
                    clipPath: `polygon(0 0, ${rate}% 0, ${rate}% 100%, 0 100%)`,
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 1,
                  }}
                >
                  {formattedRate}
                </div>
              </div>
            );
          },
        };
      }
      return column;
    });
  }, []);

  const table = useReactTable({
    data: processedData.flatMap((group) =>
      group.items.map((item) => ({ ...item, mch_name: group.mch_name }))
    ),
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    columnResizeMode: "onChange",
  });

  // 병합된 셀 정보를 계산
  const mergedCells = useMemo(() => {
    const mergedInfo = {};
    let currentIndex = 0;

    processedData.forEach((group) => {
      mergedInfo[group.mch_name] = {
        startIndex: currentIndex,
        rowSpan: group.items.length,
      };
      currentIndex += group.items.length;
    });

    return mergedInfo;
  }, [processedData]);

  if (dataQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (dataQuery.isError) {
    return <div>Error: {dataQuery.error.message}</div>;
  }

  return (
    <StyledTable
      className="t_table"
      striped
      bordered
      hover
      responsive
      size="sm"
    >
      <thead className="t_thead">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr className="t_tr" key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                className="t_th center"
                key={header.id}
                colSpan={header.colSpan}
                style={{
                  width: header.getSize(),
                  minWidth: header.getSize(),
                }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="t_tbody">
        {table.getRowModel().rows.map((row, rowIndex) => (
          <tr className="t_tr" key={row.id}>
            {row.getVisibleCells().map((cell) => {
              if (cell.column.id === "mch_name") {
                const mchName = cell.getValue();
                const mergedInfo = mergedCells[mchName];
                if (mergedInfo && mergedInfo.startIndex === rowIndex) {
                  return (
                    <td
                      className="t_td"
                      key={cell.id}
                      rowSpan={mergedInfo.rowSpan}
                      style={{
                        verticalAlign: "middle",
                        textAlign: "center",
                        width: cell.column.getSize(),
                        minWidth: cell.column.getSize(),
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                }
                return null;
              }
              return (
                <td
                  className="t_td"
                  key={cell.id}
                  style={{
                    width: cell.column.getSize(),
                    minWidth: cell.column.getSize(),
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
}

export default SummaryItem;

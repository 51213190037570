export const cards = [
  { id: 1, content: "생산성" },
  { id: 2, content: "작업자" },
  { id: 3, content: "불량" },
  { id: 4, content: "위험성평가 총건수" },
  { id: 5, content: "설비 노후화율" },
  { id: 6, content: "고장도수율" },
  { id: 7, content: "TOP3" },
  { id: 8, content: "일상점검율" },
  { id: 9, content: "보전계획준수율" },
  { id: 10, content: "융합" },
  { id: 11, content: "공정능력" },
  { id: 12, content: "10월" },
];

export const fusionData = [
  {
    id: 1,
    label1: "설비사용율",
    color1: "#E8FFE6",
    label2: "설비불량율",
    color2: "#E0F8FF",
  },
  {
    id: 2,
    label1: "설비가동율",
    color1: "#E8FFE6",
    label2: "UPH",
    color2: "#E0F8FF",
  },
  {
    id: 3,
    label1: "일상점검율",
    color1: "#E8FFE6",
    label2: "고장율",
    color2: "#E0F8FF",
  },
  {
    id: 4,
    label1: "위험지수",
    color1: "#E8FFE6",
    label2: "UPH",
    color2: "#E0F8FF",
  },
];

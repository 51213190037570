import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Pagination } from '@mui/material';
import { DataGrid, gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';

const columns = [
  {
    flex: 0.1,
    minWidth: 140,
    field: 'asset_name',
    headerName: '장비명',
    headerAlign: 'center',
    align: 'center',
  },
  {
    flex: 0.1,
    minWidth: 140,
    field: 'asset_code',
    headerName: '설비코드',
    headerAlign: 'center',
    align: 'center',
  },
  {
    flex: 0.35,
    minWidth: 140,
    field: 'broken_description',
    headerName: '고장현상',
    headerAlign: 'center',
    align: 'center',
  },
  {
    flex: 0.35,
    minWidth: 140,
    field: 'maintenance_description',
    headerName: '정비내용',
    headerAlign: 'center',
    align: 'center',
  },
  {
    flex: 0.1,
    minWidth: 140,
    field: 'broken_date',
    headerName: '고장날짜',
    headerAlign: 'center',
    align: 'center',
  },
];

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <>
      <Typography color="textSecondary" flexGrow={1}>
        {`Showing ${page * 10 + 1} to ${Math.min((page + 1) * 10, apiRef.current.state.pagination.rowCount)} of ${apiRef.current.state.pagination.rowCount} entries`}
      </Typography>
      <Pagination
        variant='outlined'
        shape="rounded"
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(_, newPage) => apiRef.current.setPage(newPage - 1)}
        showFirstButton
        showLastButton
      />
    </>
  );
};

const RiskTableDialog = ({ open, onClose, data, assetInfo }) => {
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
  // const [loaded, setLoaded] = useState(false);

  // useEffect(() => {
  //   if (data.length > 0) {
  //     setLoaded(true);
  //   }
  // }, [data]);

  const handleDialogClose = (event) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        maxWidth="lg"
        scroll="body"
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            onClose();
          }
        }}
        sx={{ '& .MuiDialog-paper': { 
          overflow: 'visible',
          backgroundColor: 'rgba(47, 51, 73, 0.8);',
          border: 'solid 2px rgb(63 68 99)'
        }}}
      >
        <DialogTitle id='simple-dialog-title'
          sx={{
            borderBottom: 'solid 1px #464646;'
          }}
        >
          <Typography variant="h3" sx={{ mb: 3, color: '#ced4da' }}>
            고장 이력 - {assetInfo}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            px: (theme) => [`${theme.spacing(5)} !important`, `${theme.spacing(10)} !important`],
            py: (theme) => [`${theme.spacing(8)} !important`, `${theme.spacing(10)} !important`]
          }}
        >
          {/* {loaded ? ( */}
            <DataGrid
              autoHeight
              getRowHeight={() => 52}
              pagination
              rows={data}
              columns={columns}
              // checkboxSelection
              disableRowSelectionOnClick
              pageSizeOptions={[10, 25, 50]}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              slots={{
                pagination: CustomPagination,
              }}
              sx={{
                '& .MuiDataGrid-container--top [role="row"], .MuiDataGrid-container--bottom [role="row"]': {
                  background: 'none !important',
                },
                '& .MuiDataGrid-cell': {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              }}
            />
          {/* ) : (
            <Typography>Loading...</Typography>
          )} */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RiskTableDialog;
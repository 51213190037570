import React, { useState } from 'react';
import QrReader from 'react-qr-scanner';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setQrData } from '../../../slice/authSlice';

const QrScanPage = () => {
  const [qrData, setLocalQrData] = useState(''); // QR 코드에서 읽어온 데이터
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleScan = (data) => {
    if (data) {
      setLocalQrData(data.text || data);
      dispatch(setQrData(data.text || data));
      console.log("QR 스캔 결과:", data);

      navigate('/mobile');
    }
  };

  const handleError = (error) => {
    console.error("QR 인식 에러: ", error);
  };

  const previewStyle = {
    width: '100%',
    height: 'auto',
  };

  return (
    <Box sx={{ p: 4, textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>QR 코드 스캔</Typography>

      {/* QrScanner 컴포넌트 */}
      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={previewStyle}
        constraints={{
          video: { facingMode: "environment" }
        }}
      />

      {/* QR 코드 결과 표시 */}
      <Typography variant="body1" sx={{ mt: 2 }}>
        스캔 결과: {qrData ? qrData : 'QR 코드를 스캔하세요'}
      </Typography>

      {/* 버튼으로 돌아가기 */}
      <Button 
        variant="contained" 
        color="primary" 
        sx={{ mt: 3 }} 
        onClick={() => navigate('/mobile')}
      >
        메인으로 돌아가기
      </Button>
    </Box>
  );
};

export default QrScanPage;
import React from 'react';

function PictInPictTopRight(props) {
	return (
		<svg height="22" width="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
	<g fill="#ffffff">
		<path d="M9.63 12.38l-2.48 2.47" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M18.7 12.1v3.3a3.3 3.3 0 0 1-3.3 3.3h-8.8a3.3 3.3 0 0 1-3.3-3.3v-8.8a3.3 3.3 0 0 1 3.3-3.3h3.3" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M17.05 8.8h-2.2q-1.65 0-1.65-1.65v-2.2q0-1.65 1.65-1.65h2.2q1.65 0 1.65 1.65v2.2q0 1.65-1.65 1.65z" fill="#ffffff" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M7.7 11h3.3v3.3l-3.3-3.3z" fill="#ffffff" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
	</g>
</svg>
	);
};

export default PictInPictTopRight;
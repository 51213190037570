import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';

// Draggable Paper component
const PaperComponent = (props) => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

const CCTVDialog = ({ open, onClose, videoUrl, assetInfo }) => {

  const handleDialogClose = (event) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        scroll="body"
        hideBackdrop={true}
        PaperComponent={PaperComponent}
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            onClose();
          }
        }}
        sx={{ '& .MuiDialog-paper': { 
          overflow: 'visible',
          backgroundColor: 'rgba(47, 51, 73, 0.8);',
          border: 'solid 2px rgb(63 68 99)'
        }}}
      >
        <DialogTitle
          id="draggable-dialog-title"
          sx={{
            borderBottom: 'solid 1px #464646',
            cursor: 'move',
          }}
        >
          <Typography variant="h3" sx={{ mb: 3, color: '#ced4da' }}>
            CCTV - {assetInfo}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: '1.5rem !important'
          }}
        >
          <Box>
            <video width="100%" controls>
              <source src={videoUrl} type="video/mp4" />
              해당 브라우저는 동영상을 지원하지 않습니다.
            </video>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CCTVDialog;
export const assetColumns = [
  {
    id: "select-col",
    header: ({ table }) => (
      <input
        type="checkbox"
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected() || undefined}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => (
      <input
        type="checkbox"
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  },
  {
    id: "asset_code",
    accessorKey: "asset_code",
    header: "설비식별코드(관리번호)",
  },
  { id: "asset_name", accessorKey: "asset_name", header: "설비명" },
  { id: "mchId", accessorKey: "mchId", header: "머신ID" },
  { id: "asset_type", accessorKey: "asset_type", header: "설비분류" },
  { id: "asset_group", accessorKey: "asset_group", header: "설비그룹" },
  { id: "asset_grade", accessorKey: "asset_grade", header: "설비등급" },
  {
    id: "asset_manufacturer",
    accessorKey: "asset_manufacturer",
    header: "제작사",
  },
  { id: "asset_model", accessorKey: "asset_model", header: "모델명" },
  { id: "asset_spec", accessorKey: "asset_spec", header: "규격" },
  { id: "asset_purchase", accessorKey: "asset_purchase", header: "구입일자" },
  {
    id: "asset_manufacture",
    accessorKey: "asset_manufacture",
    header: "제작일자",
  },
  { id: "asset_usable", accessorKey: "asset_usable", header: "내용년수" },
  { id: "asset_size", accessorKey: "asset_size", header: "설비 Size(mm)" },
  { id: "asset_voltage", accessorKey: "asset_voltage", header: "전압" },
  { id: "asset_wattage", accessorKey: "asset_wattage", header: "전력" },
  { id: "asset_manual", accessorKey: "asset_manual", header: "메뉴얼" },
  { id: "asset_status", accessorKey: "asset_status", header: "설비상태" },
  { id: "asset_description", accessorKey: "asset_description", header: "설명" },
];

function formatDate(dateObject) {
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");
  const hours = String(dateObject.getHours()).padStart(2, "0");
  const minutes = String(dateObject.getMinutes()).padStart(2, "0");
  const seconds = String(dateObject.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

// export const itemColumns = [
//   { id: "item_name", accessorKey: "item_name", header: "아이템명", size: 120 },
//   {
//     id: "start_date",
//     accessorKey: "start_date",
//     header: "시작시간",
//     cell: ({ getValue }) => {
//       return formatDate(new Date(getValue()));
//     },
//     // cell: ({ getValue }) => {
//     //   const date = new Date(getValue());
//     //   const year = date.getFullYear();
//     //   const month = date.getMonth() + 1;
//     //   const day = date.getDate();
//     //   const hours = date.getHours();
//     //   const minutes = date.getMinutes();
//     //   const ampm = hours < 12 ? "오후" : "오전";
//     //   const formattedHours = hours % 12 || 12;

//     //   return `${year}-${month}-${day} ${ampm} ${formattedHours}시 ${minutes
//     //     .toString()
//     //     .padStart(2, "0")}분`;
//     // },
//     size: 230,
//   },
//   {
//     id: "finish_date",
//     accessorKey: "finish_date",
//     header: "완료시간",
//     cell: ({ getValue }) => {
//       return formatDate(new Date(getValue()));
//     },
//     // cell: ({ getValue }) => {
//     //   const date = new Date(getValue());
//     //   const year = date.getFullYear();
//     //   const month = date.getMonth() + 1;
//     //   const day = date.getDate();
//     //   const hours = date.getHours();
//     //   const minutes = date.getMinutes();
//     //   const ampm = hours < 12 ? "오후" : "오전";
//     //   const formattedHours = hours % 12 || 12;

//     //   return `${year}년 ${month}월 ${day}일 ${ampm} ${formattedHours}시 ${minutes
//     //     .toString()
//     //     .padStart(2, "0")}분`;
//     // },
//     size: 230,
//   },
//   {
//     id: "planned_qty",
//     accessorKey: "planned_qty",
//     header: "계획 수량",
//     size: 120,
//   },
//   {
//     id: "result_qty",
//     accessorKey: "result_qty",
//     header: "생산 수량",
//     size: 120,
//   },
//   { id: "rate", accessorKey: "rate", header: "달성률", size: 120 },
// ];

export const itemColumns = [
  { id: "mch_name", accessorKey: "mch_name", header: "장비명", size: 200 },
  { id: "item_name", accessorKey: "item_name", header: "제품명", size: 200 },
  {
    id: "start_date",
    accessorKey: "start_date",
    header: "시작 시간",
    cell: ({ getValue }) => {
      return formatDate(new Date(getValue()));
    },
    size: 250,
  },
  { id: "item_qty", accessorKey: "item_qty", header: "계획 수량", size: 200 },
  {
    id: "result_qty",
    accessorKey: "result_qty",
    header: "생산 수량",
    size: 200,
  },
  { id: "rate", accessorKey: "rate", header: "달성률", size: 120 },
];

export const lineCoulmns = [
  { id: "line_name", accessorKey: "line_name", header: "라인명", size: 120 },
  { id: "mch_name", accessorKey: "mch_name", header: "장비명", size: 120 },
  { id: "item_name", accessorKey: "item_name", header: "아이템명", size: 120 },
  { id: "item_qty", accessorKey: "item_qty", header: "생산수량", size: 120 },
  {
    id: "result_qty",
    accessorKey: "result_qty",
    header: "생산수량",
    size: 120,
  },
  { id: "rate", accessorKey: "rate", header: "달성률", size: 120 },
];

export const resultColumns = [
  { id: "item_name", accessorKey: "item_name", header: "아이템명", size: 120 },
  { id: "line_name", accessorKey: "line_name", header: "라인명", size: 120 },
  { id: "mch_name", accessorKey: "mch_name", header: "장비명", size: 120 },
  { id: "plan_qty", accessorKey: "plan_qty", header: "계획수량", size: 120 },
  {
    id: "result_qty",
    accessorKey: "result_qty",
    header: "생산수량",
    size: 120,
  },
  { id: "rate", accessorKey: "rate", header: "달성률", size: 120 },
];

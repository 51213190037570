import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChartBox, Dim, GanttOverlay, SummaryCloseBtn } from "../Graph/styles";
import { gantt } from "dhtmlx-gantt";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import { useDataGanttTemporary } from "../../hooks/useDataGanttTemporary";
import "dhtmlx-gantt/codebase/skins/dhtmlxgantt_meadow.css";
import { setGanttStatus } from "../../slice/authSlice";
import styled from "styled-components";

const FixedHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.div`
  flex-grow: 1;
  text-align: center;
  width: 100%;
`;

function GanttGraph() {
  let ganttRef = useRef();
  const { gData } = useDataGanttTemporary();
  const ganttStatus = useSelector((state) => state.auth.ganttStatus);
  const dispatch = useDispatch();
  useEffect(() => {
    gantt.plugins({
      tooltip: true,
    });
    gantt.config.sort = true;
    gantt.config.drag_move = false;
    gantt.config.duration_unit = "minute";
    gantt.config.scales = [
      {
        unit: "hour",
        format: function (date) {
          return date.getHours() + "시";
        },
        step: "1",
        scale_height: 50,
        min_column_width: 45,
      },
    ];
    gantt.config.start_date = new Date(2024, 9, 22, 8);
    gantt.config.end_date = new Date(2024, 9, 22, 23);
    gantt.config.columns = [
      {
        name: "text",
        label: "장비명",
        width: 120,
        align: "center",
        resize: false,
      },
    ];
    gantt.attachEvent("onTaskDblClick", function (id, e) {
      return false;
    });
    gantt.templates.tooltip_text = function (start, end, task) {
      return (
        "<b>· 아이템 : " +
        task.item_name +
        " </b>" +
        "<br /><b>· 주문 : " +
        task.order_name +
        " </b>" +
        "<br /><b>· 공정 : " +
        task.proc_name +
        "</b>" +
        "<br /><b>· 수량 : " +
        task.item_qty +
        "개" +
        "</b>" +
        "</br><b>· 시작 :  <b/>" +
        start.getHours() +
        "시 " +
        start.getMinutes() +
        "분" +
        "</br><b>· 종료 :  <b/>" +
        end.getHours() +
        "시 " +
        end.getMinutes() +
        "분"
      );
    };
    gantt.config.row_height = 25;
    gantt.config.scale_height = 25;
    gantt.init(ganttRef.current);
    gantt.parse({
      data: gData,
    });
  }, [ganttStatus, gData]);
  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };
  return (
    <>
      <Dim
        onClick={() => dispatch(setGanttStatus(false))}
        style={{ display: ganttStatus ? "block" : "none" }}
      >
        <GanttOverlay
          //    onClick={handleOverlayClick}
          style={{ zIndex: 0 }}
          onClick={handleOverlayClick}
        >
          <FixedHeader>
            <Title className="summary_title">Gantt 차트</Title>
            <SummaryCloseBtn onClick={() => dispatch(setGanttStatus(false))} />
          </FixedHeader>
          <hr className="divider" />
          <div
            style={{
              position: "absolute",
              top: "55%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <ChartBox onClick={onCloseDim}> */}
            <div ref={ganttRef} style={{ width: "100%", height: "420px" }} />
            {/* </ChartBox> */}
          </div>
        </GanttOverlay>
      </Dim>
    </>
  );
}

export default GanttGraph;

export const createCommonOptions = (
  title,
  handleOverlay,
  isLarge = false,
  showLegend = false,
  isDataZoom = false
) => {
  const commonOptions = {
    title: {
      text: title,
      left: "center",
      textStyle: {
        color: "white",
        fontWeight: "300",
        fontFamily: "NOTO SANS KOREAN",
        fontSize: isLarge ? 30 : 20,
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: { type: "shadow" },
      textStyle: { fontSize: isLarge ? 20 : 10 },
    },
    grid: {
      top: isLarge ? "15%" : "18%",
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
  };
  if (!isLarge) {
    commonOptions.toolbox = {
      feature: {
        myTool1: {
          show: true,
          title: "확대하기",
          icon: "path://M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z",
          onclick: function () {
            handleOverlay(title.toLowerCase().replace(/\s+/g, ""));
          },
        },
      },
      itemSize: 25,
      top: 10,
      iconStyle: { color: "white" },
    };
  }
  if (showLegend) {
    commonOptions.legend = {
      orient: "horizontal",
      top: isLarge ? "50" : "30",
      right: "center",
      width: isLarge ? "1250px" : "350px",
      height: "50px",
      textStyle: {
        fontSize: isLarge ? 20 : 10,
        color: "white",
      },
    };
  }
  if (isDataZoom) {
    commonOptions.dataZoom = [
      {
        yAxisIndex: 0,
        type: "slider",
        zoomLock: false,
        width: 10,
        right: 20,
        top: 40,
        bottom: 40,
        start: 90,
        end: 100,
        handleSize: 0,
        showDetail: false,
        zoomOnMouseWheel: true,
        moveOnMouseWheel: true,
        preventDefaultMouseWheel: true,
      },
      {
        type: "inside",
        yAxisIndex: 0,
        start: 0,
        end: 100,
        zoomOnMouseWheel: false,
        moveOnMouseMove: true,
        moveOnMouseWheel: true
      },
    ];
  }
  return commonOptions;
};

export const createBarChartOptions = (
  title,
  xAxisData,
  series,
  yAxisConfig,
  handleOverlay,
  isLarge = false,
  showLegend = false,
  isDataZoom = false
) => ({
  ...createCommonOptions(title, handleOverlay, isLarge, showLegend, isDataZoom),
  xAxis: {
    type: "category",
    data: xAxisData,
    axisLabel: {
      formatter: (value) => {
        if (value?.includes("-")) {
          const month = value.split("-")[1].replace(/^0/, "");
          return month + "월";
        }
        return value;
      },
      color: "white",
      fontFamily: "NOTO SANS KOREAN",
      fontSize: isLarge ? 20 : 10,
      fontWeight: "100",
    },
  },
  yAxis: yAxisConfig,
  series: series.map((s) => ({
    ...s,
    label: {
      show: true,
      position:
        s.type === "line"
          ? "top"
          : s.name?.includes("실적")
          ? "top"
          : "insideTop",
      color: s.type === "line" ? "black" : "white",
      backgroundColor: s.type === "line" ? "white" : undefined,
      borderColor: s.type === "line" ? "#FC913A" : undefined,
      borderWidth: s.type === "line" ? 1 : undefined,
      borderRadius: s.type === "line" ? 5 : undefined,
      padding: s.type === "line" ? [1, 1] : undefined,
      formatter: function (params) {
        return s.type === "line"
          ? params.value + "%"
          : params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      fontSize: isLarge ? 20 : 10,
    },
  })),
});
export const createBarChartOptionsWithDays = (
  title,
  xAxisData,
  series,
  yAxisConfig,
  handleOverlay,
  isLarge = false,
  showLegend = false
) => ({
  ...createCommonOptions(title, handleOverlay, isLarge, showLegend),
  xAxis: {
    type: "category",
    data: xAxisData,
    axisLabel: {
      formatter: (value) => {
        if (value?.includes("-")) {
          const month = value.split("-")[1].replace(/^0/, "");
          const day = value.split("-")[2].replace(/^0/, "");
          return month + "/" + day;
        }
        return value;
      },
      color: "white",
      fontFamily: "NOTO SANS KOREAN",
      fontSize: isLarge ? 20 : 10,
      fontWeight: "100",
    },
  },
  yAxis: yAxisConfig,
  series: series.map((s) => ({
    ...s,
    label: {
      show: true,
      position:
        s.type === "line"
          ? "top"
          : s.name?.includes("실적")
          ? "top"
          : "insideTop",
      color: s.type === "line" ? "black" : "white",
      backgroundColor: s.type === "line" ? "white" : undefined,
      borderColor: s.type === "line" ? "#FC913A" : undefined,
      borderWidth: s.type === "line" ? 1 : undefined,
      borderRadius: s.type === "line" ? 5 : undefined,
      padding: s.type === "line" ? [1, 1] : undefined,
      formatter: function (params) {
        return s.type === "line"
          ? params.value + "%"
          : params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      fontSize: isLarge ? 20 : 10,
    },
  })),
});

export const createHorizontalBarChartOptions = (
  title,
  yAxisData,
  series,
  xAxisConfig,
  handleOverlay,
  isLarge = false,
  showLegend = true,
  isDataZoom = false
) => ({
  ...createCommonOptions(title, handleOverlay, isLarge, showLegend, isDataZoom),
  xAxis: xAxisConfig,
  yAxis: {
    type: "category",
    axisTick: { show: false },
    data: yAxisData,
    splitLine: { show: false },
    axisLabel: {
      color: "white",
      fontFamily: "NOTO SANS KOREAN",
      fontSize: isLarge ? 20 : 10,
      fontWeight: "100",
    },
  },
  series: series.map((s) => ({
    ...s,
    label: {
      show: true,
      position: "right",
      formatter: function (params) {
        return params.value === "0.0" ? "" : params.value;
      },
      fontSize: isLarge ? 20 : 10,
      color: "white",
    },
  })),
});

export const createHorizontalBarChartProductivity = (
  title,
  yAxisData,
  series,
  xAxisConfig,
  handleOverlay,
  isLarge = false,
  showLegend = true,
  tooltip
) => ({
  ...createCommonOptions(title, handleOverlay, isLarge, showLegend),
  xAxis: xAxisConfig,
  yAxis: {
    type: "category",
    axisLabel: {
      color: "white",
      fontFamily: "NOTO SANS KOREAN",
      fontSize: 10,
      fontWeight: "100",
    },
    data: yAxisData,
  },
  series: series.map((s) => ({
    ...s,
  })),
  tooltip,
});

export const createTreemapChartOptions = (
  title,
  data,
  handleOverlay,
  isLarge = false,
  showLegend = false
) => ({
  ...createCommonOptions(title, handleOverlay, isLarge, showLegend),
  series: [
    {
      type: "treemap",
      data: data,
      label: {
        show: true,
        formatter: "{b}: {c}%",
        fontSize: isLarge ? 20 : 10,
      },
      breadcrumb: { show: false },
    },
  ],
});

export const createScatterChartOptions = (
  title,
  data,
  handleOverlay,
  isLarge = false,
  showLegend = false
) => ({
  ...createCommonOptions(title, handleOverlay, isLarge, showLegend),
  tooltip: {
    showDelay: 0,
    type: "cross",
    formatter: function (params) {
      if (params.componentType !== "markLine") {
        return `${params.marker}${params.seriesName} <br /> 고장도수율 : ${params.data[0]}<br /> 고장강도율 : ${params.data[1]}`;
      } else return null;
    },
    axisPointer: {
      show: true,
      type: "cross",
      lineStyle: {
        type: "dashed",
        width: 1,
      },
    },
  },
  xAxis: {
    splitLine: { show: false },
    type: "value",
    min: 0,
    max: function (value) {
      return Math.max.apply(
        null,
        data.map((item) => item.data[0])
      );
    },
    scale: false,
  },
  yAxis: {
    splitLine: { show: false },
  },
  grid: {
    left: "3%",
    right: "10%",
    bottom: "3%",
    containLabel: true,
  },
  series: data.map((series) => ({
    ...series,
    type: "scatter",
    symbolSize: isLarge ? 20 : 10,
    label: {
      show: true,
      position: "top",
      color: "white",
      fontSize: isLarge ? 20 : 10,
      formatter: function (params) {
        return params.seriesName;
      },
    },
  })),
});

import React, { useState } from "react";
import SummaryItem from "../Item";
import SummaryLine from "../Line";
import { SummaryCloseBtn, Dim, SummaryOverlay } from "../../Graph/styles";
import { useDispatch, useSelector } from "react-redux";
import { setSummaryItemStatus } from "../../../slice/authSlice";
import styled from "styled-components";
import { Divider } from "@mui/material";

const FixedHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ButtonGroup = styled.div`
  display: flex;
  position: absolute;
  right: 0px;
  align-items: center;
`;

const ScrollableContent = styled.div`
  max-height: calc(100% - 80px);
  overflow-y: auto;
  padding: 10px;
`;

const Title = styled.div`
  flex-grow: 1;
  text-align: center;
  width: 100%;
`;

function MergeSummary() {
  const [activeTab, setActiveTab] = useState("item");
  const dispatch = useDispatch();
  const tabStyle = {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "20px",
    position: "absolute",
  };

  const buttonStyle = (isActive) => ({
    padding: "10px 20px",
    marginRight: "10px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    backgroundColor: isActive ? "#007bff" : "#f0f0f0",
    color: isActive ? "white" : "black",
  });
  const summaryItemStatus = useSelector(
    (state) => state.auth.summaryItemStatus
  );
  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };
  return (
    <>
      {summaryItemStatus && (
        <Dim onClick={() => dispatch(setSummaryItemStatus(false))}>
          <SummaryOverlay onClick={handleOverlayClick} $isDaily>
            <FixedHeader>
              {/* <ButtonGroup>
                <button
                  style={buttonStyle(activeTab === "item")}
                  onClick={() => setActiveTab("item")}
                >
                  Item
                </button>
                <button
                  style={buttonStyle(activeTab === "line")}
                  onClick={() => setActiveTab("line")}
                >
                  Line
                </button>
                <SummaryCloseBtn
                  onClick={() => dispatch(setSummaryItemStatus(false))}
                />
              </ButtonGroup> */}
              <Title className="summary_title">
                일일생산계획/실적 ({activeTab === "item" ? "장비별" : "라인별"})
              </Title>
              <SummaryCloseBtn
                onClick={() => dispatch(setSummaryItemStatus(false))}
              />
            </FixedHeader>
            <hr className="divider" />
            <ScrollableContent>
              {activeTab === "item" ? <SummaryItem /> : <SummaryLine />}
            </ScrollableContent>
          </SummaryOverlay>
        </Dim>
      )}
    </>
  );
}
export default MergeSummary;
